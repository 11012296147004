import axios from "axios";
import { Dispatch } from "redux";
import { baseURL } from "../globals/constants";
import { setUserInfo, setUserToken } from "../redux/slices/AuthenticationSlice";
import { ForgetPasswordValues, ResetPasswordValues } from "../utils/forms";
import { showErrorToast } from "../utils/Toast";

interface LoginFormValues {
    email: string;
    password: string;
}

export const loginUser = async (
    values: LoginFormValues,
    dispatch:Dispatch,
    isMobile:boolean
) => {
    try {
        const response: any = await axios.post(
            `${baseURL}/v1/auth/login`,
            {
                email: values.email,
                password: values.password,
            },
            {
                withCredentials: true,
            }
        );
        console.log("response ", response)
        localStorage.setItem("access_token", response?.data.tokens.access.token);
        localStorage.setItem("user", JSON.stringify(response.data.user));
        localStorage.setItem("role", JSON.stringify(response?.data?.user?.role));
        dispatch(setUserInfo(response.data.user));
        dispatch(setUserToken(response.data.tokens))
        return response;
    } catch (error: any) {
        const message = error?.response?.data?.message || "An error occurred during login";
        showErrorToast(message, isMobile)
    }
};

export const forgetPassword=async(
    values:ForgetPasswordValues,
    isMobile:boolean
)=>{
    try {
      
        const response=await axios.post(
            `${ baseURL }/v1/auth/forgot-password`,
            {
                email:values.email
            },
            {
                withCredentials:true
            }
        );
        return response;
    } catch (error:any) {
        const message = error?.response?.data?.message || "An error occurred while requesting forget password";
        showErrorToast(message, isMobile)
    }
}


export const resetPassword = async (
    values: ResetPasswordValues,
    isMobile:boolean
) => {
    try {
        const token = localStorage.getItem("token");
        const response = await axios.post(
            `${baseURL}/v1/auth/reset-password?token=${token}`,
            {
                password: values.newPassword
            },
            {
                withCredentials: true
            }
        );
        console.log("response in auth  ", response)
        return response;
    } catch (error:any) {
        const message = error?.response?.data?.message || "An error occurred while requesting reset password";
        showErrorToast(message, isMobile)
    }
}


export interface User {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    password: string;
}

export const signupUser = async(data: User) => {
    try{
        console.log("Base url: ", baseURL);
        
        const response: any = axios.post(`${baseURL}/v1/auth/registerAdmin`, data);
        return response.data;
    }
    catch(err){
        console.log("Error signing up");
        throw err;
    }
} 

