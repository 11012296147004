import { Box, Button, Grid, Pagination, TextField, Typography, useMediaQuery } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Layout from "../components/Layout";
import { useAppDispatch, useAppSelector } from "../redux";
import { AppDispatch } from "../redux/store";
import { Question, useAddTeamForm } from "../utils/forms";
import { useTranslation } from "react-i18next";
import TeamTable from "./TeamTable";
import TeamMemberNameTable from "./TeamMemberNameTable";

const UpdateViewTeam = () => {
  const [t, i18n] = useTranslation("global");
  const [isView, setIsView] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const dispatch: AppDispatch = useAppDispatch();
  const location = useLocation();
  const { tenantId, record } = location.state || "";
  const navigate = useNavigate();
  const { teamId }: any = useParams();
  const query = new URLSearchParams(useLocation().search);
  const isEdit = query.get("isEdit");
  const [isEditing, setIsEditing] = useState<Boolean>(isEdit === "true" ? true : false);
  const isMobile = useMediaQuery("(max-width:600px)");
  const formik = useAddTeamForm(teamId || "", isMobile);
  const [result, setResult] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalResults, setTotalResults] = useState(0);

  function handleCancel(): void {
    setIsEditing(false);
    setIsView(false);
    handleGoBack();
  }
  const handleGoBack = async () => {
    navigate(-1);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    console.log("Entered here", formik.values);
    formik.handleSubmit();
    e.preventDefault();
    setIsEditing(false);
    setIsView(false);
  };
  const setUpTeamName = () => {
    formik.setValues({
      tenantId:record.tenantId,
      teamName: record.teamName,
    });
  };

  useEffect(() => {
    setUpTeamName();
  }, [record, isEdit]);
  console.log("record in tema view", record);

  return (
    <Layout>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          marginRight: "10%",
          marginTop: "2%",
          marginBottom: "1%",
        }}
      >
        <Button onClick={handleCancel} variant="outlined" sx={{ minWidth: "1%", marginLeft: "1%" }}>
          {"<"}
        </Button>
        <Typography variant="h4" fontWeight="bold" style={{ marginLeft: "6%" }}>
          {isEdit === "true" ? "Update" : "View"} Team{" "}
        </Typography>
      </div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          marginX: "10%",
          // width:"60%"
          // marginBottom: "5%",
          // justifyContent: 'flex-start',
          // backgroundColor: 'blue'
        }}
      >
        <Box component="form" onSubmit={handleSubmit}>
          <TextField
            autoFocus
            required
            fullWidth
            id="breefName"
            name="teamName"
            label="Team Name"
            type="text"
            variant="outlined"
            onChange={formik.handleChange("teamName")}
            value={formik.values.teamName}
            error={formik.touched.teamName && Boolean(formik.errors.teamName)}
            helperText={formik.touched.teamName && formik.errors.teamName}
            disabled={isEdit === "true" ? false : true}
          />

          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            {isEditing ? (
              <>
                <Button
                  sx={{
                    color: "#ED670A",
                    mr: 1,
                    borderColor: "#ED670A",
                    "&:hover": { borderColor: "#ED670A" },
                  }}
                  onClick={handleCancel}
                  variant="outlined"
                >
                  {t("updatebreef.t1")}
                </Button>
                <Button
                  sx={{ backgroundColor: "#ED670A", "&:hover": { backgroundColor: "#d55a07" } }}
                  type="submit"
                  variant="contained"
                >
                  {t("updatebreef.t2")}
                </Button>
              </>
            ) : (
              <></>
            )}
          </Box>
        </Box>
      </Box>
      <TeamMemberNameTable
        results={record.membersName}
        page={currentPage}
        limit={pageLimit}
        totalPages={totalPages}
        totalResults={totalResults}
      ></TeamMemberNameTable>
       
    </Layout>
  );
};

export default UpdateViewTeam;
