
import { Box, Container, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
const NotFound: React.FC = () => {
  return (
    <Container maxWidth="sm">
      <Typography variant="h1" align="center" gutterBottom>
        404 Not Found
      </Typography>
      <Typography variant="h4" align="center" gutterBottom>
        Oops! The page you're looking for doesn't exist.
      </Typography>
      <Typography variant="body1" align="center" gutterBottom>
        Go back to <Link to="/">home</Link>.
      </Typography>
    </Container>
  );
};

export default NotFound;