import { Box, Button, Container, CssBaseline, FormControlLabel, Grid, Link, TextField, ThemeProvider, Typography, createTheme, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useResetPasswordForm } from '../../utils/forms';
import Logo from '../../asset/logo/LOGO_ORANGE.svg';
import { Copyright } from '../../utils/CopyRightPage';
import { string } from 'yup';

const theme = createTheme({
    palette: {
        primary: {
            main: '#3f51b5',
        },
        secondary: {
            main: '#f50057',
        },
    },
});
export default function ResetPassword() {
    const [formSubmitted, setFormSubmitted] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');
    const formik = useResetPasswordForm(isMobile);
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setFormSubmitted(true);
        formik.handleSubmit(e as any);
    };
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const tokenFromUrl = urlParams.get('token');
        if (tokenFromUrl) {
            localStorage.setItem("token", tokenFromUrl);
        }
    }, []);
    return (
        <ThemeProvider theme={theme} >
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 5,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <img src={Logo} alt="Logo" style={{ width: '40%', height: '20%' }} />
                    <div style={{ background: theme.palette.primary.main, width: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center', borderRadius: '4px' }}>
                        <Typography component="h1" variant="h5" style={{
                            color: 'white', padding: '4px', marginTop: 3, marginBottom: 2
                        }}>
                            Change Password
                        </Typography>
                    </div>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="newPassword"
                            label="New Password"
                            type="password"
                            id="newPassword"
                            onChange={formik.handleChange("newPassword")}
                            value={formik.values.newPassword}
                            error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                            helperText={formik.touched.newPassword && formik.errors.newPassword}

                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Change Password
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="/forget-password" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="/sign-up" variant="body2">
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Box mt={8}>
                    <Copyright />
                </Box>
            </Container>
        </ThemeProvider >
    )
}
