import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { useAddCategoryForm } from "../../utils/forms";
import { deleteCategory, getAllCategories } from "../../service/CategoryService";
import { AppDispatch } from "../../redux/store";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface ChipData {
  categoryName: string;
  id: string;
}
export default function ManageCategory() {
  const [open, setOpen] = React.useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [chipData, setChipData] = React.useState<readonly ChipData[]>();
  const [chipColors, setChipColors] = useState<string[]>([]);
  const [isDelete, setIsDelete] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [id, setId] = useState("");
  const isMobile = useMediaQuery("(max-width:600px)");
  const formik = useAddCategoryForm(isEdit, id, isMobile);
  const dispatch: AppDispatch = useDispatch();
  const categoriesData = useAppSelector((state) => state.category.categories.results);
  const navigate = useNavigate();
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [t, i18n] = useTranslation("global");

  const handleDelete = (chipToDelete: string) => () => {
    setIsDelete(true);
    setId(chipToDelete);
    setIsDeleteModal(true);
  };
  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
    setIsEdit(false);
    setId("");
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormSubmitted(true);
    formik.handleSubmit(e as any);
    setOpen(false);
  };
  const generateRandomLightColor = () => {
    const letters = "89ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * letters.length)];
    }
    return color;
  };
  const generateChipColors = () => {
    if (chipData) {
      const colors = chipData.map(() => generateRandomLightColor());
      setChipColors(colors);
    }
  };
  useEffect(() => {
    generateChipColors();
  }, []);

  useEffect(() => {
    if (isDelete) {
      setIsDelete(false);
    }
    fetchCategories();
  }, [formik.isSubmitting, isDelete]);
  const fetchCategories = async (page?: number, limit?: number) => {
    let pageNo = 1;
    let pageLimit = 10;
    if (page) {
      pageNo = page;
    }
    if (limit) {
      pageLimit = limit;
    }
    await getAllCategories(dispatch, isMobile, navigate);
  };
  const handleEdit = (index: string) => {
    setIsEdit(true);
    setOpen(true);
    setId(index.toString());
    if (chipData) {
      const chip = chipData.find((item) => item.id === index);
      formik.setValues({
        categoryName: chip ? chip.categoryName : "",
      });
    }
  };

  const handleCancelDelete = () => {
    setIsDeleteModal(false);
  };

  const handleDeleteCategory = async () => {
    setIsDeleteModal(false);
    setIsDelete(true);
    await deleteCategory(id, isMobile, navigate);
    setIsDelete(true);
  };

  return (
    <Layout>
      <Dialog open={isDeleteModal} onClose={handleCancelDelete}>
        <DialogTitle>{t("managecat.t1")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t("managecat.t2")}</DialogContentText>
        </DialogContent>
        <Box sx={{ display: "flex", justifyContent: "center", m: 2 }}>
          <Button
            onClick={handleCancelDelete}
            sx={{ backgroundColor: "#ED670A", mr: 1 }}
            variant="outlined"
          >
            {t("managecat.t3")}
          </Button>
          <Button onClick={handleDeleteCategory} color="error" variant="contained">
            {t("managecat.t4")}
          </Button>
        </Box>
      </Dialog>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1>{t("managecat.t5")}</h1>
        <Button
          variant="contained"
          onClick={handleClickOpen}
          sx={{
            mt: 3,
            mb: 2,
            alignSelf: "flex-end",
            marginRight: "10%",
            backgroundColor: "#ED670A",
            "&:hover": { backgroundColor: "#d55a07" },
          }}
        >
          {t("managecat.t6")}
        </Button>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{t("managecat.t7")}</DialogTitle>
          <DialogContent>
            <DialogContentText>{t("managecat.t8")}</DialogContentText>
            <Box component="form" onSubmit={handleSubmit}>
              <TextField
                autoFocus
                required
                margin="dense"
                id="categoryName"
                name="categoryName"
                label={t("managecat.t9")}
                type="text"
                fullWidth
                variant="standard"
                onChange={formik.handleChange("categoryName")}
                value={formik.values.categoryName}
                error={formik.touched.categoryName && Boolean(formik.errors.categoryName)}
                helperText={formik.touched.categoryName && formik.errors.categoryName}
              />
              <DialogActions>
                <Button sx={{ color: "#ED670A" }} onClick={handleClose}>
                  {t("managecat.t10")}
                </Button>
                <Button sx={{ color: "#ED670A" }} type="submit">
                  {t("managecat.t11")}
                </Button>
              </DialogActions>
            </Box>
          </DialogContent>
        </Dialog>
        <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap", gap: "10px" }}>
          {categoriesData &&
            categoriesData.map((data, index) => (
              <Chip
                key={data.id}
                label={data.categoryName}
                onDelete={handleDelete(data.id)}
                onClick={() => handleEdit(data.id)}
                sx={{ backgroundColor: chipColors[index] }}
              />
            ))}
        </div>
      </Box>
    </Layout>
  );
}
