import { createSlice } from "@reduxjs/toolkit";

interface Tenant {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    role: string;
    isEmailVerified: boolean;
    tenantId?: TenantDetails;
    teamId?: TeamDetails;
    id: string;
    status: string;
}

interface TenantDetails {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    role: string;
    isEmailVerified: boolean;
    id: string;
    status: string;
}

interface TeamDetails {
    teamName: string;
    tenantId: string;
    id: string;
}

interface TenantResponse {
    results: Tenant[];
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
}
const InitialValues:TenantResponse={
   results:[{
       firstName: "",
       lastName: "",
       email: "",
       phoneNumber: "",
       role: "",
       isEmailVerified: false,
       id: "",
       status: "",
   }],
   limit:0,
   page:0,
   totalPages:0,
   totalResults:0
}
const InitialState={
    tenants:InitialValues
}
export const TenantSlice=createSlice({
    initialState:InitialState,
    name:'tenants',
    reducers:{
        setTenantInfo:(state,action)=>{
            state.tenants=action.payload
        }
    }
})
// export const {setTenantInfo} = TenantSlice.actions
// export default TenantSlice.reducer


export const { setTenantInfo } = TenantSlice.actions
export default TenantSlice.reducer