import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { useAddAdminForm } from "../../utils/forms";
import {
  deleteUser,
  getAdminUsers,
  getAllAdminUsers,
  updateAdminDashUser,
} from "../../service/UserService";
import { AppDispatch } from "../../redux/store";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Tenant {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  role: string;
  isEmailVerified: boolean;
  tenantId: string;
  id: string;
}

interface Team {
  teamName: string;
  tenantId: string;
  id: string;
}

interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  role: string;
  isEmailVerified: boolean;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: "bold",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const TableWrapper = styled(TableContainer)({
  margin: "auto",
  marginTop: "1%",
  width: "80%",
}) as typeof TableContainer;

const Title = styled(Typography)({
  marginBottom: "16px",
});

export default function ManageAdmin() {
  const [adminData, setAdminData] = useState<User[]>([]);
  const [adminResponse, setAdminResponse] = useState<any>();
  const [open, setOpen] = React.useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isView, setIsView] = useState(false);
  const [titleText, setTitleText] = useState("Add New Admin");
  const [isDisabledEditing, setIsDisabledEditing] = useState(false);
  const role = ["contentAdmin", "admin"];
  const token = localStorage.getItem("access_token");
  const [id, setId] = useState("");
  const isMobile = useMediaQuery("(max-width:600px)");
  const formik = useAddAdminForm(isEditing, id, isMobile);
  const [isDelete, setIsDelete] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [t, i18n] = useTranslation("global");
  const [page, setPage] = useState(0); // Page number starts from 0
  const [rowsPerPage, setRowsPerPage] = useState(10); // Rows per page

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    fetchAdmins(newPage, rowsPerPage); // Fetch games when page changes
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    fetchAdmins(0, parseInt(event.target.value, 10)); // Fetch games when rows-per-page changes
  };

  const handleClickOpen = () => {
    formik.resetForm();
    setTitleText("Add New Admin");
    setOpen(true);
  };
  const handleViewClick = (userId: string) => {
    console.log(`View clicked for user with ID ${userId}`);
    setIsView(true);
    setIsDisabledEditing(true);
    const record = adminData.find((item) => item.id === userId);
    if (record) {
      formik.values.email = record.email;
      formik.values.phoneNo = record.phoneNumber;
      formik.values.role = record.role;
      formik.values.firstName = record.firstName;
      formik.values.lastName = record.lastName;
    }
    setOpen(true);
  };

  const handleEditClick = (userId: string) => {
    console.log(`Edit clicked for user with ID ${userId}`);
    console.log("Entered herenfoiwnefoifnoi");

    setId(userId);
    setIsEditing(true);
    const record = adminData.find((item) => item.id === userId);
    console.log("record: ", record);

    if (record) {
      formik.values.email = record.email;
      formik.values.phoneNo = record.phoneNumber;
      formik.values.role = record.role;
      formik.values.firstName = record.firstName;
      formik.values.lastName = record.lastName;
    }
    setOpen(true);
  };
  const fetchAdmins = async (page: number = 0, limit: number = rowsPerPage) => {
    const fetchedAdmins = await getAdminUsers(dispatch, isMobile, navigate, limit, page + 1);
    if (fetchedAdmins) {
      console.log("fetched Admins: ", fetchedAdmins);
      setAdminResponse(fetchedAdmins);
      setAdminData(fetchedAdmins.results);
    }
  };
  useEffect(() => {
    fetchAdmins();
  }, [dispatch, isMobile, navigate]);

  // const fetchAdminUsers = async (role: string[], page?: number, limit?: number) => {
  //   let pageNo = 1;
  //   let pageLimit = 10;
  //   if (page) {
  //     pageNo = page;
  //   }
  //   if (limit) {
  //     pageLimit = limit;
  //   }
  //   const data = await getAllAdminUsers(dispatch, role, isMobile, navigate, pageLimit, pageNo);
  //   if (data) {
  //     setAdminData(data);
  //   }
  // };

  const handleDeleteUser = async () => {
    setIsDeleteModal(false);
    await deleteUser(userId, isMobile, navigate);
    fetchAdmins();
  };

  const handleDeleteClick = (userId: string) => {
    setUserId(userId);
    setIsDeleteModal(true);
  };
  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
    setIsEditing(false);
    setIsView(false);
    setIsDisabledEditing(false);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("isediting val", isEditing);
    if (isEditing) {
      const values = {
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        password: formik.values.password,
      };
      console.log("Updated vals:", userId);

      try {
        const resp = await updateAdminDashUser(id, values);
        console.log("Successfully updated admin user", resp);
        fetchAdmins();
      } catch (err) {
        console.log("Error updating admin info", err);
      }
    } else {
      formik.handleSubmit(e as any);
    }

    setFormSubmitted(true);
    setOpen(false);
  };
  useEffect(() => {
    isView ? setTitleText(t("manageadmin.t1")) : setTitleText(t("manageadmin.t2"));
  }, [isEditing, isView]);

  useEffect(() => {
    if (isDelete) {
      setIsDelete(false);
    }
    fetchAdmins();
  }, [formik.isSubmitting, isDelete]);

  const handleCancelDelete = () => {
    setIsDeleteModal(false);
  };

  return (
    <Layout>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginLeft: "10%",
          marginRight: "10%",
          marginTop: "2%",
        }}
      >
        <Typography variant="h4" fontWeight="bold">
          {t("manageadmin.t3")}
        </Typography>
        <Button
          variant="contained"
          onClick={handleClickOpen}
          sx={{
            alignSelf: "flex-end",
            backgroundColor: "#ED670A",
            "&:hover": { backgroundColor: "#d55a07" },
          }}
        >
          {t("manageadmin.t4")}
        </Button>
      </div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "10%",
        }}
      >
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{titleText}</DialogTitle>
          <DialogContent>
            <DialogContentText>{t("manageadmin.t5")}</DialogContentText>
            <Box component="form" onSubmit={handleSubmit}>
              <TextField
                autoFocus
                required
                margin="dense"
                id="firstName"
                name="firstName"
                label={t("manageadmin.t6")}
                type="text"
                fullWidth
                variant="standard"
                onChange={formik.handleChange("firstName")}
                value={formik.values.firstName}
                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                helperText={formik.touched.firstName && formik.errors.firstName}
                disabled={isDisabledEditing}
              />
              <TextField
                required
                margin="dense"
                id="lastName"
                name="lastName"
                label={t("manageadmin.t7")}
                type="text"
                fullWidth
                variant="standard"
                onChange={formik.handleChange("lastName")}
                value={formik.values.lastName}
                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                helperText={formik.touched.lastName && formik.errors.lastName}
                disabled={isDisabledEditing}
              />
              {!isEditing && (
                <TextField
                  required
                  margin="dense"
                  id="email"
                  name="email"
                  label={t("manageadmin.t8")}
                  type="email"
                  fullWidth
                  variant="standard"
                  onChange={formik.handleChange("email")}
                  value={formik.values.email}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  disabled={isDisabledEditing}
                />
              )}
              <TextField
                margin="dense"
                id="phoneNumber"
                name="phoneNumber"
                label={t("manageadmin.t9")}
                disabled={isEditing ? true : false}
                type="text"
                fullWidth
                variant="standard"
                onChange={formik.handleChange("phoneNo")}
                value={formik.values.phoneNo}
                error={formik.touched.phoneNo && Boolean(formik.errors.phoneNo)}
                helperText={formik.touched.phoneNo && formik.errors.phoneNo}
              />
              <TextField
                margin="dense"
                id="password"
                name="password"
                label={t("manageadmin.t10")}
                type="password"
                fullWidth
                variant="standard"
                onChange={formik.handleChange("password")}
                value={formik.values.password}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                disabled={isDisabledEditing}
              />
              {!isEditing && (
                <>
                  <InputLabel id="role-label">{t("manageadmin.t19")}</InputLabel>
                  <Select
                    required
                    margin="dense"
                    id="role"
                    name="role"
                    label="Role"
                    fullWidth
                    variant="standard"
                    onChange={formik.handleChange}
                    value={formik.values.role}
                    disabled={isDisabledEditing}
                  >
                    <MenuItem value="admin"> {t("manageadmin.t11")}</MenuItem>
                    <MenuItem value="contentAdmin">{t("manageadmin.t12")}</MenuItem>
                  </Select>
                </>
              )}
              <DialogActions>
                <Button sx={{ color: "#ED670A" }} onClick={handleClose}>
                  {t("manageadmin.t13")}
                </Button>
                <Button sx={{ color: "#ED670A" }} type="submit">
                  {t("manageadmin.t14")}
                </Button>
              </DialogActions>
            </Box>
          </DialogContent>
        </Dialog>

        <Dialog open={isDeleteModal} onClose={handleCancelDelete}>
          <DialogTitle>{t("manageadmin.t15")}</DialogTitle>
          <DialogContent>
            <DialogContentText>{t("manageadmin.t16")}</DialogContentText>
          </DialogContent>
          <Box sx={{ display: "flex", justifyContent: "center", m: 2 }}>
            <Button onClick={handleCancelDelete} color="primary" variant="outlined" sx={{ mr: 1 }}>
              {t("manageadmin.t13")}
            </Button>
            <Button onClick={handleDeleteUser} color="error" variant="contained">
              {t("manageadmin.t22")}
            </Button>
          </Box>
        </Dialog>

        <TableWrapper component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ backgroundColor: "#ED670A" }} align="center">
                  {t("manageadmin.t17")}
                </StyledTableCell>
                <StyledTableCell sx={{ backgroundColor: "#ED670A" }} align="center">
                  {t("manageadmin.t18")}
                </StyledTableCell>
                <StyledTableCell sx={{ backgroundColor: "#ED670A" }} align="center">
                  {t("manageadmin.t19")}
                </StyledTableCell>
                <StyledTableCell sx={{ backgroundColor: "#ED670A" }} align="center">
                  {t("manageadmin.t20")}
                </StyledTableCell>
                <StyledTableCell sx={{ backgroundColor: "#ED670A" }} align="center">
                  {t("manageadmin.t21")}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {adminData.map((user) => (
                <StyledTableRow key={user.id}>
                  <TableCell align="center">{user.firstName + " " + user.lastName}</TableCell>
                  <TableCell align="center">{user.email}</TableCell>
                  <TableCell align="center">{user.role}</TableCell>
                  <TableCell align="center">{user.phoneNumber}</TableCell>
                  <TableCell align="center">
                    <IconButton aria-label="view" onClick={() => handleViewClick(user.id)}>
                      <VisibilityIcon />
                    </IconButton>
                    <IconButton aria-label="edit" onClick={() => handleEditClick(user.id)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton aria-label="delete" onClick={() => handleDeleteClick(user.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          {adminResponse && (
            <TablePagination
              component="div"
              count={adminResponse.totalResults || 0} // Total number of games
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            />
          )}
        </TableWrapper>
      </Box>
    </Layout>
  );
}
