import { createSlice } from "@reduxjs/toolkit";

interface Category {
    categoryName: string;
    id: string;
}

interface Game {
    gameName: string;
    categoryId: Category;
    gameCode: string;
    id: string;
    gameDescription: string;
}

interface ApiResponse {
    results: Game[];
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
}
const InitialValues: ApiResponse = {
    results: [],
    page: 0,
    limit: 0,
    totalPages: 0,
    totalResults: 0
}
const InitialState = {
    games: InitialValues
}

export const GameSlice = createSlice({
    name: 'games',
    initialState: InitialState,
    reducers: {
        setGamesData: (state, action) => {
            state.games = action.payload
        }
    }
})

export const { setGamesData } = GameSlice.actions
export default GameSlice.reducer