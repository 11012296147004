import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Input,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { deleteBreef } from "../service/BreefService";
import { useTranslation } from "react-i18next";
import { deleteUser, updateNewInvitedUser } from "../service/NewInvitedUser";
import { getTeamById } from "../service/teamService";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: "bold",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const TableWrapper = styled(TableContainer)({
  margin: "auto",
  marginTop: "1%",
  width: "80%",
}) as typeof TableContainer;

export interface Result {
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
}
export interface SimpleDialogProps {
  open: boolean;
  user: any;
  onClose: () => void;
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onUpdate: () => void;
  setMember:any
}

interface TeamMemberTableProps {
  results: Result[];
  page: number;
  limit: number;
  totalPages: number;
  totalResults: number;
}

const TeamMemberNameTable: React.FC<TeamMemberTableProps> = ({
  results,
  page,
  limit,
  totalPages,
}) => {
  const [t] = useTranslation("global");
  const [id, setId] = useState("");
  const { teamId }: any = useParams();
  const [users, setUsers] = useState<any[]>([]);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [team, setTeam] = useState<string>("teamName");
  const [del, setDel] = useState(false);
  const [click, setClick] = useState(false);
  const [showMess, setShowMess] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [errorMess, setErrorMess] = useState<string>("");
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");
  const [members, setMembers] = useState(results); 
  const [rowsPerPage, setRowsPerPage] = useState(10); // Rows per page
  const navigate = useNavigate();

  const handleCancelDelete = () => {
    setIsDeleteModal(false);
  };

  const handleClickOpen = (user: any) => {
    setSelectedUser(user);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedUser(null);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedUser) {
      setSelectedUser({
        ...selectedUser,
        [e.target.name]: e.target.value,
      });
    }
  };
  const handleUpdate = async () => {
    try {

      console.log("Updated user details: ", selectedUser);
      setOpen(false);
      setSelectedUser(null);
      setClick(false);
    } catch (err: any) {
      if (err.status === 500) {
      } else if (err.status === 404) {
        setErrorMess(t("dashboardTeamEditPage.error.team'sMemberNotFoundError"));
        return;
      }
      setErrorMess(t("dashboardTeamEditPage.error.updateUserError"));
      console.log("Error updating user details", err);
    }
  };

  return (
    <>
      {selectedUser && (
        <SimpleDialog
          open={open}
          onClose={handleClose}
          user={selectedUser}
          onInputChange={handleInputChange}
          onUpdate={handleUpdate}
          setMember={setMembers}
        />
      )}
      <Dialog open={isDeleteModal} onClose={handleCancelDelete}>
        <DialogTitle>{t("teamMembertable.t1")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t("teamMembertable.t2")}</DialogContentText>
        </DialogContent>
        <Box sx={{ display: "flex", justifyContent: "center", m: 2 }}>
          <Button onClick={handleCancelDelete} color="primary" variant="outlined" sx={{ mr: 1 }}>
            {t("teamMembertable.t3")}
          </Button>
          {/* <Button onClick={handleDeleteBreef} color="error" variant="contained">
              {t("teamMembertable.t4")}
            </Button> */}
        </Box>
      </Dialog>
      <TableWrapper component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ backgroundColor: "#ED670A" }} align="center">
                {t("teamMembertable.t5")}
              </StyledTableCell>
              <StyledTableCell sx={{ backgroundColor: "#ED670A" }} align="center">
                {t("teamMembertable.t6")}
              </StyledTableCell>
              <StyledTableCell sx={{ backgroundColor: "#ED670A" }} align="center">
                {t("teamMembertable.t7")}
              </StyledTableCell>
              <StyledTableCell sx={{ backgroundColor: "#ED670A" }} align="center">
                {t("teamMembertable.t8")}
              </StyledTableCell>
              <StyledTableCell sx={{ backgroundColor: "#ED670A" }} align="center">
                {t("teamMembertable.t9")}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {members?.map((member) => {
              return (
                <StyledTableRow key={member.id}>
                  <TableCell align="center">{member.firstName}</TableCell>
                  <TableCell align="center">{member.lastName}</TableCell>
                  <TableCell align="center"> {member.phoneNumber}</TableCell>
                  <TableCell align="center"> {member.email}</TableCell>
                  <TableCell align="center">
                    <IconButton aria-label="edit" onClick={() => handleClickOpen(member)}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableWrapper>
    </>
  );
  
  
  

};

function SimpleDialog(props: SimpleDialogProps) {
  const [t] = useTranslation("global");
  const { teamId }: any = useParams();

  let { onClose, user, open, onInputChange, onUpdate,setMember } = props;
  return (
    <Dialog onClose={onClose} open={open}>
      <Box
        sx={{
          padding: 4,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width:"28vw"
        }}
      >
        <DialogTitle sx={{ fontSize: 24, fontWeight: 400 }}>
          {t("TeamEditModal.dialogTitle")}
        </DialogTitle>
        <Box
          sx={{ marginBottom: 4, display: "flex", flexDirection: "column",width:"100%"}}
        >
          <Typography sx={{ fontSize: 19,marginTop:1 }}>{t("TeamEditModal.firstName")}</Typography>
          <TextField
            variant="outlined"
            name="firstName"
            value={user.firstName}
            onChange={onInputChange}
            className="w-full border border-[#ED670A] p-2 rounded-[8px]"
            placeholder={t("TeamEditModal.firstName")}
          />
          <Typography sx={{ fontSize: 19,marginTop:3  }}>{t("TeamEditModal.lastName")}</Typography>
          <TextField
            variant="outlined"
            name="lastName"
            value={user.lastName}
            onChange={onInputChange}
            className="w-full border border-[#ED670A] p-2 rounded-[8px]"
            placeholder={t("TeamEditModal.lastName")}
          />

          {/* <Typography sx={{ fontSize: 19,marginTop:3  }}>{t("TeamEditModal.email")}</Typography>
          <TextField
            variant="outlined"
            name="email"
            value={user.email}
            onChange={onInputChange}
            className="w-full border border-[#ED670A] p-2 rounded-[8px]"
            placeholder={t("dashboardTeamEditPage.email")}
          /> */}
        </Box>

        <Button
          sx={{ backgroundColor: "#fd7e14", color: "white" ,paddingLeft:2,paddingRight:2}}
          onClick={async () => {
            try {
              const resp = await updateNewInvitedUser(user._id, {
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
              });
              console.log("response of teams", teamId);
              const results = await getTeamById(teamId);
              setMember(results)

              onUpdate();
              console.log("click after save");
            } catch (err: any) {
              console.log("Error updating user details", err);

              onClose();
            }
          }}
          // className="w-20 h-20 rounded-full bg-[#ED670A] mt-8 text-white font-semibold uppercase hover:scale-110"
        >
          {t("TeamEditModal.update")}
        </Button>
      </Box>
    </Dialog>
  );
}


export default TeamMemberNameTable;
