import { createSlice } from "@reduxjs/toolkit";

interface Tenant {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    role: string;
    isEmailVerified: boolean;
    tenantId: string;
    teamId?: string;
    id: string;
}

interface Game {
    id: string;
    status: string,
    gameName: string,
    categoryId:string
}

interface Question {
    question: string;
    options: string[];
    answer: number;
    _id: string;
}

interface Result {
    period:string;
    breefName: string;
    videoUrl: string;
    questions: Question[];
    tenantId: Tenant;
    gameId: Game;
    status: string;
    expiry: string;
    id: string;
}
interface Tenant {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    role: string;
    isEmailVerified: boolean;
    tenantId: string;
    id: string;
}
interface ApiResponse {
    results: Result[];
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
}
const InitialValues: ApiResponse = {
    results: [],
    page: 0,
    limit: 0,
    totalPages: 0,
    totalResults: 0,
}
const InitialState = {
    breefData: InitialValues
}
export const BreefsDataSlice = createSlice({
    initialState: InitialState,
    name: 'breefs',
    reducers: {
        setBreefsData: (state, action) => {
            state.breefData = action.payload
        }
    }
})

export const { setBreefsData } = BreefsDataSlice.actions
export default BreefsDataSlice.reducer