import { EmojiPeople, Group } from "@mui/icons-material";
import { Card, CardContent, Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";

interface TenantParticipantProps {
    activeTenants: number;
    passiveTenants: number;
    activeParticipants: number;
    passiveParticipants: number;
}
const StyledCard = styled(Card)(({ theme }) => ({
    backgroundColor: theme.palette.success.main,
    color: theme.palette.background.paper,
    borderRadius: theme.spacing(2),
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    transition: '0.3s',
    maxHeight: '200px',
    width: '30%',
    margin: '1%',
    '&:hover': {
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
    },
}));

const Title = styled(Typography)({
    marginBottom: '10px',
});

const Text = styled(Typography)({
    marginBottom: '7px',
    display: 'flex',
    alignItems: 'center',
});

const Icon = styled('span')({
    marginRight: '8px',
});

const TenantInfo: React.FC<TenantParticipantProps> = ({
    activeTenants,
    passiveTenants,
    activeParticipants,
    passiveParticipants,
}) => {
    const [t, i18n] = useTranslation("global");
    
    return (
        <StyledCard>
            <CardContent>
                <Title variant="h6" fontWeight="bold">{t('hometenant.t1')}</Title>
                <Text >{t('hometenant.t2')} {activeTenants}</Text>
                <Text>{t('hometenant.t3')} {passiveTenants}</Text>
                <Text>
                    <Icon><Group /></Icon>
                    {t('hometenant.t4')} {activeParticipants}
                </Text>
                <Text>
                    <Icon><EmojiPeople /></Icon>
                    {t('hometenant.t5')} {passiveParticipants}
                </Text>
            </CardContent>
        </StyledCard>
    );
};
export default TenantInfo;