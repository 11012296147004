import { createSlice } from "@reduxjs/toolkit";

interface Result {
    categoryName: string;
    id: string;
}

interface ApiResponse {
    results: Result[];
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
}

const InitialValues: ApiResponse = {
    results: [],
    page: 0,
    limit: 0,
    totalPages: 0,
    totalResults: 0
}
const InitialState = {
    categories: InitialValues
}

export const CategorySlice = createSlice({
    name: 'categories',
    initialState: InitialState,
    reducers: {
        setCategoriesData: (state, action) => {
            state.categories = action.payload
        }
    }
})

export const { setCategoriesData } = CategorySlice.actions
export default CategorySlice.reducer