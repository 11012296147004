import axios from "axios";
import { AddBreefValues } from "../utils/forms";
import { baseURL } from "../globals/constants";
import { Dispatch } from "redux";
import { setBreefsData } from "../redux/slices/BreefsSlice";
import { showErrorToast } from "../utils/Toast";
import { NavigateFunction, Navigation, useNavigate } from "react-router-dom";

export const updateBreefAdmin = async(id:string, values:any) => {
    const token = localStorage.getItem("access_token");
    try {
        const value={
                breefName: values.breefName,
                videoUrl: values.videoUrl,
                questions: values.questions,
                tenantId: values.tenantId,
                status: values.status,
                gameId: values.gameId,
                answer:values.answer
        }
        const response: any = await axios.put(
            `${baseURL}/v1/Breefs/${id}`,
            value,
            {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            },
        );
        return response;
    } catch (error: any) {
        console.log("Error updating user");
    }
}

export const updateBreef = async (
    values: AddBreefValues,
    id: string,
    isMobile:boolean,
    navigate: NavigateFunction
) => {
    const token = localStorage.getItem("access_token");
    try {
        const value={
                breefName: values.breefName,
                videoUrl: values.videoUrl,
                questions: values.questions,
                tenantId: values.tenantId,
                status: values.status,
                gameId: values.gameId,
                answer:values.answer
        }
        const response: any = await axios.put(
            `${baseURL}/v1/Breefs/${id}`,
            value,
            {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            },
        );
        return response;
    } catch (error: any) {
        if (error.response.status == 401) {
            showErrorToast("Session Expired.Please login", isMobile);
            localStorage.removeItem("access_token")
            navigate("/")
        }else{
            const message = error?.response?.data?.message || "An error occurred while updating breef";
            showErrorToast(message, isMobile)
        }
    }
};

export const deleteBreef = async (
    id: string,
    navigate: NavigateFunction,
    isMobile: boolean
) => {
    const token = localStorage.getItem("access_token");
    try {
        const response: any = await axios.delete(
            `${baseURL}/v1/Breefs/${id}`,
            {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            },
        );
        return response;
    } catch (error: any) {
        console.log("error ", error)
        if (error.response.status == 401) {
            showErrorToast("Session Expired.Please login", isMobile);
            localStorage.removeItem("access_token")
            navigate("/")
        }
    }
};

export const getAllBreefs = async (
    dispatch: Dispatch,
    isMobile:boolean,
    navigate: NavigateFunction,
    tenantId?: any,
    pageLimit?: number,
    pageNo?: number
) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.get(
            `${baseURL}/v1/Breefs?limit=${pageLimit}&page=${pageNo}&tenantId=${tenantId}`,
            {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }
        );
        dispatch(setBreefsData(response.data))
        return response.data;
    } catch (error:any) {
        console.log("error ", error)
        if (error.response.status == 401) {
            showErrorToast("Session Expired.Please login", isMobile);
            localStorage.removeItem("access_token")
            navigate("/")
        }
    }
};