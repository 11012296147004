import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface IUser {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    role: '';
    isEmailVerified: boolean;
    tenantId: string;
    teamId: string;
    id: string;
}
interface ITokens {
    access: {
        token: string;
        expires: string;
    };
    refresh: {
        token: string;
        expires: string;
    };
}
const User: IUser = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    role: '',
    isEmailVerified: false,
    tenantId: '',
    teamId: '',
    id: '',
}
const Token: ITokens = {
    access: {
        token: '',
        expires: ''
    },
    refresh: {
        token: '',
        expires: ''
    }
}


const InitialState = {
    User: User,
    token: Token
}
export const userInformationSlice = createSlice({
    initialState: InitialState,
    name: 'user',
    reducers: {
        setUserInfo: (state, action: PayloadAction<IUser>) => { state.User = action.payload },
        setUserToken: (state, action: PayloadAction<ITokens>) => {
            state.token = action.payload
        },
        clearUserInfo: (state, action) => {
            state.User = action.payload
        },
        clearUserToken: (state, action) => {
            state.token = action.payload
        }

    }
})

export const { setUserInfo, setUserToken, clearUserInfo, clearUserToken } = userInformationSlice.actions
export default userInformationSlice.reducer