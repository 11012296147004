import axios from "axios";
import { AddAdminValues } from "../utils/forms";
import { baseURL } from "../globals/constants";
import { Dispatch } from "redux";
import { setTenantInfo } from "../redux/slices/TenantSlice";
import { showErrorToast } from "../utils/Toast";
import { NavigateFunction } from "react-router-dom";

interface IAddAdminValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  password: string;
  role: string;
}

interface IUpdateAdminValues {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  password: string;
}

export const addUser = async (
  values: AddAdminValues,
  isMobile: boolean,
  navigate: NavigateFunction
) => {
  const token = localStorage.getItem("access_token");
  try {
    console.log("inside api call", values);
    const value: IAddAdminValues = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phoneNumber: values.phoneNo,
      password: values.password,
      role: values.role,
    };
    const response: any = await axios.post(`${baseURL}/v1/users`, value, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error: any) {
    if (error.response.status == 401) {
      showErrorToast("Session Expired.Please login", isMobile);
      localStorage.removeItem("access_token");
      navigate("/");
    } else {
      const message = error?.response?.data?.message || "An error occurred adding new user";
      showErrorToast(message, isMobile);
    }
  }
};

interface IUpdateTenantValues {
  firstName: string;
  lastName: string;
  password?: string;
}
export const updateAdminDashUser = async (userId: string, values: IUpdateTenantValues) => {
  try {
    const token = localStorage.getItem("access_token");
    console.log("USerid", userId);

    const response: any = await axios.patch(`${baseURL}/v1/users/${userId}`, values, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    console.log("Error updating tenant", err);
  }
};

export const updateUser = async (
  values: AddAdminValues,
  id: string,
  isMobile: boolean,
  navigate: NavigateFunction
) => {
  const token = localStorage.getItem("access_token");
  try {
    const value: IUpdateAdminValues = {
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNo,
      password: values.password,
    };
    const response: any = await axios.patch(`${baseURL}/v1/users/${id}`, value, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error: any) {
    if (error.response.status == 401) {
      showErrorToast("Session Expired.Please login", isMobile);
      localStorage.removeItem("access_token");
      navigate("/");
    } else {
      const message = error?.response?.data?.message || "An error occurred updating user";
      showErrorToast(message, isMobile);
    }
  }
};
export const deleteUser = async (id: string, isMobile: boolean, navigate: NavigateFunction) => {
  const token = localStorage.getItem("access_token");
  try {
    const response: any = await axios.delete(`${baseURL}/v1/users/${id}`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error: any) {
    if (error.response.status == 401) {
      showErrorToast("Session Expired.Please login", isMobile);
      localStorage.removeItem("access_token");
      navigate("/");
    }
    console.log("error ", error);
  }
};

export const getAllAdminUsers = async (
  dispatch: Dispatch,
  role: string[],
  isMobile: boolean,
  navigate: NavigateFunction,
  pageLimit?: number,
  pageNo?: number
) => {
  const token = localStorage.getItem("access_token");
  try {
    const response = await axios.get(
      `${baseURL}/v1/users?role=admin&limit=${pageLimit}&page=${pageNo}`,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("Response is: ", response);
    if (role.includes("tenant")) {
      dispatch(setTenantInfo(response.data));
    }
    if (role.includes("admin" || "tenantAdmin")) {
      console.log("admin data");
    }
    return response.data.results;
  } catch (error: any) {
    if (error.response.status == 401) {
      showErrorToast("Session Expired.Please login", isMobile);
      localStorage.removeItem("access_token");
      navigate("/");
    }
    console.log("error ", error);
  }
};

export const getTenantUsers = async (
  dispatch: Dispatch,
  isMobile: boolean,
  navigate: NavigateFunction,
  pageLimit?: number,
  pageNo?: number
) => {
  const token = localStorage.getItem("access_token");
  try {
    const response = await axios.get(
      `${baseURL}/v1/users?role=tenant&limit=${pageLimit}&page=${pageNo}`,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("Response in getTenantUsers is: ", response);
    return response.data;
  } catch (error: any) {
    if (error.response.status === 401) {
      showErrorToast("Session Expired. Please login", isMobile);
      localStorage.removeItem("access_token");
      navigate("/");
    }
    console.log("error ", error);
  }
};

export const getAdminUsers = async (
  dispatch: Dispatch,
  isMobile: boolean,
  navigate: NavigateFunction,
  pageLimit?: number,
  pageNo?: number
) => {
  const token = localStorage.getItem("access_token");
  try {
    const response = await axios.get(
      `${baseURL}/v1/users?role=admin&limit=${pageLimit}&page=${pageNo}`,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("Response in getAdminUsers is: ", response);
    return response.data;
  } catch (error: any) {
    if (error.response.status === 401) {
      showErrorToast("Session Expired. Please login", isMobile);
      localStorage.removeItem("access_token");
      navigate("/");
    }
    console.log("error ", error);
  }
};
