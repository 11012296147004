import {
  Box,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { useAddBreefForm } from "../../utils/forms";

import { useAppDispatch, useAppSelector } from "../../redux";
import { AppDispatch } from "../../redux/store";
import { getAllBreefs } from "../../service/BreefService";
import BreefTable from "./BreefTable";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Tenant {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  role: string;
  isEmailVerified: boolean;
  tenantId: string;
  teamId?: string;
  id: string;
}

interface Game {
  id: string;
  status: string;
  gameName: string;
}

interface Question {
  question: string;
  options: string[];
  answer: number;
  _id: string;
}

interface Result {
  period: string;
  breefName: string;
  videoUrl: string;
  questions: Question[];
  tenantId: Tenant;
  gameId: Game;
  status: string;
  expiry: string;
  id: string;
}
interface Tenant {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  role: string;
  isEmailVerified: boolean;
  tenantId: string;
  id: string;
}
interface ApiResponse {
  results: Result[];
  page: number;
  limit: number;
  totalPages: number;
  totalResults: number;
}

export default function ManageBreef() {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [id, setId] = useState("");
  const isMobile = useMediaQuery("(max-width:600px)");
  const formik = useAddBreefForm(id, isMobile);
  const [breefsData, setBreefsData] = useState<ApiResponse>();
  const token = localStorage.getItem("access_token");
  const [isEditing, setIsEditing] = useState(false);
  const [isView, setIsView] = useState(false);
  const [titleText, setTitleText] = useState("Add New Breef");
  const [isDisabledEditing, setIsDisabledEditing] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const dispatch: AppDispatch = useAppDispatch();
  const data = useAppSelector((state) => state.breefs);
  const navigate = useNavigate();
  const [t, i18n] = useTranslation("global");

  useEffect(() => {
    isView ? setTitleText("View Breef") : setTitleText("Edit Breef");
  }, [isEditing, isView]);

  function handleCancel(): void {
    setIsEditing(false);
    setIsView(false);
    setIsDisabledEditing(false);
  }
  const fetchBreefs = async (page?: number, limit?: number) => {
    let pageNo = 1;
    let pageLimit = 10;
    if (page) {
      pageNo = page;
    }
    if (limit) {
      pageLimit = limit;
    }
    setBreefsData(await getAllBreefs(dispatch, isMobile, navigate, pageLimit, pageNo));
  };
  useEffect(() => {
    if (isDelete) {
      setIsDelete(false);
    }
    fetchBreefs();
  }, [formik.isSubmitting, isDelete]);
  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1>{t("managebreefs.t1")}</h1>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <BreefTable
          results={data.breefData.results}
          page={0}
          limit={0}
          totalPages={0}
          totalResults={0}
        />
      </Box>
    </Layout>
  );
}
