import axios from "axios";
import { baseURL } from "../globals/constants";

export const getDashboardData = async() => {
    const token = localStorage.getItem("access_token");
        
    try{
        const response = await axios.get(`${baseURL}/v1/Home`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": 'application/json'
            }
        },);
        console.log("dashboard resp: ",response);
        return response.data;
    }
    catch(err){
        console.log(err);
    }
}