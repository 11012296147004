import axios from "axios";
import { AddTeamValues } from "../utils/forms";
import { baseURL } from "../globals/constants";
import { showErrorToast } from "../utils/Toast";
import { NavigateFunction, } from "react-router-dom";

export const getAllTeams = async (
  isMobile: boolean,
  navigate: NavigateFunction,
  tenantId?: any,
  page?: number,
  rowsPerPage?: number
) => {
  const token = localStorage.getItem("access_token");
  try {
    const response = await axios.get(
      `${baseURL}/v1/teams/getAllTeams/${tenantId}?limit=${rowsPerPage}&page=${page}`,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.log("error ", error);
    if (error.response.status === 401) {
      showErrorToast("Session Expired.Please login", isMobile);
      localStorage.removeItem("access_token");
      navigate("/");
    }
  }
};

export const getTeamById = async (
    teamId?: any,
  ) => {
    const token = localStorage.getItem("access_token");
    try {
      const response = await axios.get(
        `${baseURL}/v1/users/team/${teamId}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log("error ", error);
      if (error.response.status === 401) {
        localStorage.removeItem("access_token");
      }
    }
  };

export const updateTeamName = async (
  values: AddTeamValues,
  id: string,
  isMobile: boolean,
  navigate: NavigateFunction
) => {
  const token = localStorage.getItem("access_token");
  try {
    const value = {
        tenantId:values.tenantId,
      teamName: values.teamName,
    };
    const response: any = await axios.put(`${baseURL}/v1/teams/${id}`, value, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error: any) {
    if (error.response.status === 401) {
      showErrorToast("Session Expired.Please login", isMobile);
      localStorage.removeItem("access_token");
      navigate("/");
    } else {
      const message = error?.response?.data?.message || "An error occurred while updating breef";
      showErrorToast(message, isMobile);
    }
  }
};
