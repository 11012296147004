import axios from 'axios';

const API_BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL;

/**
 * Refreshes the access token using the refresh token.
 * @param refreshToken - The refresh token to use.
 * @returns A promise that resolves with the new access token.
 */
export const refreshAccessToken = async (): Promise<string> => {
const  refreshToken = localStorage.getItem('refreshToken'); 
  try {
    const response = await axios.post(`${API_BASE_URL}/v1/auth/refresh-tokens`, { refreshToken });
    const newAccessToken = response.data.tokens.access.token;
    console.log('Access token refreshed successfully', newAccessToken);
    localStorage.setItem('accessToken', newAccessToken);
    const newRefreshToken = response.data.tokens.refresh.token;
    localStorage.setItem('refreshToken', newRefreshToken);
    return newAccessToken;
  } catch (error :any) {
    console.error('Failed to refresh access token:', error.message);
    return  error.message;    
  }
};