import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  useMediaQuery,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppDispatch } from "../redux/store";
import { deleteBreef } from "../service/BreefService";
import { useAddBreefForm } from "../utils/forms";
import { useTranslation } from "react-i18next";
import { DataObject } from "@mui/icons-material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: "bold",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const TableWrapper = styled(TableContainer)({
  margin: "auto",
  marginTop: "1%",
  width: "80%",
}) as typeof TableContainer;

interface Tenant {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  role: string;
  isEmailVerified: boolean;
  tenantId: string;
  teamId?: string;
  id: string;
}



export interface TeamResult {
  members: number;
  membersName: [];
  teamName: string;
  tenantId: Tenant;
  id: string;
}

interface TeamTableProps {
  results: TeamResult[];
  page: number;
  limit: number;
  totalPages: number;
  totalResults: number;
}

const TeamTable: React.FC<TeamTableProps> = ({ results, page, limit, totalPages }) => {
  const [t] = useTranslation("global");
  const [id, setId] = useState("");
  const location = useLocation();

  // const { userId, record } = location.state || "";

  const [isEditing, setIsEditing] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");
  const formik = useAddBreefForm(id, isMobile);
  const navigate = useNavigate();

  console.log("is result is here", results);

  const handleViewClick = async (teamId: string) => {
    setIsView(true);
    const record = results.find((result) => result.id === teamId);
    navigate(`/team/${teamId}?isEdit=false`, {
      state: { tenantId: record?.tenantId, record: record },
    });
  };

  const handleEditClick = async (teamId: string) => {
    setIsEditing(true);
    setId(teamId);

      const record = results.find((result) => result.id === teamId);
  
    navigate(`/team/${teamId}?isEdit=true`, {
      state: { tenantId: record?.tenantId, record: record },
    });
  };

  const handleDeleteClick = (teamId: string) => {
    setId(teamId);
    setIsDeleteModal(true);
  };

  const handleDeleteBreef = async () => {
    setIsDeleteModal(false);
    await deleteBreef(id, navigate, isMobile);
    setIsDelete(true);
  };

  const handleCancelDelete = () => {
    setIsDeleteModal(false);
  };

  return (
    <>
      <Dialog open={isDeleteModal} onClose={handleCancelDelete}>
        <DialogTitle>{t("teamtable.t1")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t("teamtable.t2")}</DialogContentText>
        </DialogContent>
        <Box sx={{ display: "flex", justifyContent: "center", m: 2 }}>
          <Button onClick={handleCancelDelete} color="primary" variant="outlined" sx={{ mr: 1 }}>
            {t("teamtable.t3")}
          </Button>
          <Button onClick={handleDeleteBreef} color="error" variant="contained">
            {t("teamtable.t4")}
          </Button>
        </Box>
      </Dialog>
      <TableWrapper component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ backgroundColor: "#ED670A" }} align="center">
                {t("teamtable.t5")}
              </StyledTableCell>
              <StyledTableCell sx={{ backgroundColor: "#ED670A" }} align="center">
                {t("teamtable.t6")}
              </StyledTableCell>
              <StyledTableCell sx={{ backgroundColor: "#ED670A" }} align="center">
                {t("teamtable.t7")}
              </StyledTableCell>
              {/* <StyledTableCell sx={{ backgroundColor: "#ED670A" }} align="center">
                  {t("teamtable.t8")}
                </StyledTableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {results?.map((team) => {
              return (
                <StyledTableRow key={team.id}>
                  <TableCell align="center">{team.teamName}</TableCell>
                  <TableCell align="center">{team.members}</TableCell>
                  {/* <TableCell align="center"> {``}</TableCell> */}
                  <TableCell align="center">
                    <IconButton aria-label="view" onClick={() => handleViewClick(team.id)}>
                      <VisibilityIcon />
                    </IconButton>
                    <IconButton aria-label="edit" onClick={() => handleEditClick(team.id)}>
                      <EditIcon />
                    </IconButton>
                    {/* <IconButton aria-label="delete" onClick={() => handleDeleteClick(team.id)}>
                      <DeleteIcon />
                    </IconButton> */}
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableWrapper>
    </>
  );
};

export default TeamTable;

function setIsEditing(arg0: boolean) {
  throw new Error("Function not implemented.");
}

function setIsDeleteModal(arg0: boolean) {
  throw new Error("Function not implemented.");
}

function setIsDelete(arg0: boolean) {
  throw new Error("Function not implemented.");
}
