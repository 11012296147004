
import { Box, Button, Checkbox, Container, CssBaseline, FormControlLabel, Grid, Link, TextField, ThemeProvider, Typography, createTheme, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import Logo from '../../asset/logo/LOGO_ORANGE.svg';
import { useAppDispatch } from '../../redux';
import { AppDispatch } from '../../redux/store';
import { Copyright } from '../../utils/CopyRightPage';
import { useSignInForm } from '../../utils/forms';
import { useTranslation } from 'react-i18next';

const theme = createTheme({
    palette: {
        primary: {
            main: '#ED670A',
        },
        secondary: {
            main: '#f50057',
        },
    },
});
export default function SignIn() {
    const [t, i18n] = useTranslation("global");
    
    const [formSubmitted, setFormSubmitted] = useState(false);
    const dispatch:AppDispatch =useAppDispatch();
    const isMobile = useMediaQuery('(max-width:600px)');
    const formik = useSignInForm(dispatch,isMobile);
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.log("Entered");
        
        setFormSubmitted(true);
        formik.handleSubmit(e as any);
    };
    return (
        <ThemeProvider theme={theme} >
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 5,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <img src={Logo} alt="Logo" style={{ width: '40%', height: '20%' }} />
                    <div style={{ background: theme.palette.primary.main, width: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center', borderRadius: '4px' }}>
                        <Typography component="h1" variant="h5" style={{
                            color: 'white', padding: '4px', marginTop: 3, marginBottom: 2
                        }}>
                            {t('signin.t8')}
                        </Typography>
                    </div>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={t('signin.t6')}
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={formik.handleChange("email")}
                            value={formik.values.email}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={t('signin.t5')}
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={formik.handleChange("password")}
                            value={formik.values.password}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}

                        />
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label={t('signin.t4')}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            {t('signin.t3')}
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="/forget-password" variant="body2">
                                {t('signin.t2')}
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="/sign-up" variant="body2">
                                {t('signin.t7')}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Box mt={8}>
                    <Copyright />
                </Box>
            </Container>
        </ThemeProvider >
    );
}
