import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const showSuccessToast = (message: string, isMobile: boolean) => {
    console.log("inside success")
    toast.success(message, 
        {
        position: "top-right",
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
            borderRadius: "10px",
            margin: isMobile ? "20px" : "0px",
            fontFamily: "Outfit, sans-serif",
        },
    }
);
};

export const showErrorToast = (message: string, isMobile: boolean) => {
    toast.error(message, {
        position: "top-right",
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
            borderRadius: "10px",
            margin: isMobile ? "20px" : "0px",
            fontFamily: "Outfit, sans-serif",
        },
    });
};
