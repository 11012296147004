import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Router from './Router';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
function App() {
  return (
    <BrowserRouter>
    <Provider store={store}>
      <Router />
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
    </Provider>
    </BrowserRouter>
  );
}

export default App;
