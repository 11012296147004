import { Box, Button, Grid, TextField, Typography, useMediaQuery } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Layout from '../../components/Layout';
import { useAppDispatch, useAppSelector } from '../../redux';
import { AppDispatch } from '../../redux/store';
import { Question, useAddBreefForm } from '../../utils/forms';
import { getAllBreefs, updateBreefAdmin } from '../../service/BreefService';
import { useTranslation } from 'react-i18next';


const UpdateViewBreef = () => {
    const [t, i18n] = useTranslation("global");
    const [isView, setIsView] = useState(false)
    const [isDelete, setIsDelete] = useState(false);
    const dispatch: AppDispatch = useAppDispatch();

    const data = useAppSelector((state => state.breefs.breefData.results));
    const navigate = useNavigate();
    const { breefId }:any = useParams();
    const query = new URLSearchParams(useLocation().search);
    const isEdit = query.get('isEdit');
    const [isEditing, setIsEditing] = useState<Boolean>(isEdit === 'true'? true : false);
    const isMobile = useMediaQuery('(max-width:600px)');
    const formik = useAddBreefForm(breefId || '', isMobile);

    function handleCancel(): void {
        setIsEditing(false)
        setIsView(false)
        handleGoBack();
    }
    const handleGoBack = async () => {
        navigate(-1)
    }
    useEffect(() => {
        if (isDelete) {
            setIsDelete(false)
        }
        getAllBreefs(dispatch, isMobile, navigate);
    }, [formik.isSubmitting, isDelete])
    const handleSubmit = async(e: React.FormEvent<HTMLFormElement>) => {
        console.log("Entered here", formik.values);
        
        e.preventDefault();
        await updateBreefAdmin(breefId, formik.values);
        // formik.handleSubmit(e as any);
        setIsEditing(false)
        setIsView(false);
    };
    const setUpBreef = () => {
        const breef = data.filter((breef) => breef.id === breefId)
        const mappedQuestions = breef[0].questions.map(question => {
            return {
                question: question.question,
                options: question.options,
                answer: question.answer
            };
        });
        formik.setValues({
            period:breef[0].period,
            expiry:breef[0].expiry,
            breefName: breef[0].breefName,
            gameId: breef[0].gameId.id,
            questions: mappedQuestions,
            status: breef[0].status,
            tenantId: breef[0].tenantId.id,
            videoUrl: breef[0].videoUrl,
            gameName: breef[0].gameId.gameName,
            tenantName: breef[0].tenantId.firstName + " " + breef[0].tenantId.lastName
        });
    }
    useEffect(() => {
        setUpBreef();
    }, [breefId, isEdit])
    useEffect(() => {
        console.log("is edit ", isEdit)
    }, [])
    return (
        <Layout>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                marginRight: '10%',
                marginTop: '2%',
                marginBottom: '1%'
            }}>
                <Button onClick={handleCancel} variant="outlined" sx={{ minWidth: '1%', marginLeft: '1%' }}>
                    {'<'}
                </Button>
                <Typography variant="h4" fontWeight="bold" style={{ marginLeft: '6%' }}>{isEdit === 'true' ? 'Update' : 'View'} Breef </Typography>
                </div>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginX: '10%',
                    marginBottom: '5%',
                    // justifyContent: 'flex-start',
                    // backgroundColor: 'blue'
                }}
            >
                <Box component="form" onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={4}>
                            <TextField
                                autoFocus
                                required
                                fullWidth
                                id="breefName"
                                name="breefName"
                                label="Breef Name"
                                type="text"
                                variant="outlined"
                                onChange={formik.handleChange("breefName")}
                                value={formik.values.breefName}
                                error={formik.touched.breefName && Boolean(formik.errors.breefName)}
                                helperText={formik.touched.breefName && formik.errors.breefName}
                                disabled={isEdit === 'true' ? false : true}
                            />
                            
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <TextField
                                autoFocus
                                required
                                fullWidth
                                id="breefName"
                                name="period"
                                label="Start Date"
                                type="text"
                                variant="outlined"
                                onChange={formik.handleChange("breefName")}
                                value={formik.values.period}
                                error={formik.touched.breefName && Boolean(formik.errors.breefName)}
                                helperText={formik.touched.breefName && formik.errors.breefName}
                                disabled={ true}
                            />
                            
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <TextField
                                autoFocus
                                required
                                fullWidth
                                id="breefName"
                                name="expiry"
                                label="End Date"
                                type="text"
                                variant="outlined"
                                onChange={formik.handleChange("breefName")}
                                value={formik.values.expiry}
                                error={formik.touched.breefName && Boolean(formik.errors.breefName)}
                                helperText={formik.touched.breefName && formik.errors.breefName}
                                disabled={true}
                            />
                            
                        </Grid>
                        <Grid item  style={{ display: 'flex', justifyContent: 'start', alignItems: 'start', width: '100%' }}>
                            <ReactPlayer
                                url={formik.values.videoUrl}
                                width={'100%'}
                                playing={true}
                                loop={true}
                                controls={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                id="tenantName"
                                name="tenantName"
                                label="Tenant Name"
                                type="text"
                                variant="outlined"
                                onChange={formik.handleChange("tenantName")}
                                value={formik.values.tenantName}
                                error={formik.touched.tenantName && Boolean(formik.errors.tenantName)}
                                helperText={formik.touched.tenantName && formik.errors.tenantName}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="gameName"
                                name="gameName"
                                label="Game Name"
                                type="text"
                                variant="outlined"
                                onChange={formik.handleChange("gameName")}
                                value={formik.values.gameName}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="status"
                                name="status"
                                label="Status"
                                type="text"
                                variant="outlined"
                                onChange={formik.handleChange("status")}
                                value={formik.values.status}
                                error={formik.touched.status && Boolean(formik.errors.status)}
                                helperText={formik.touched.status && formik.errors.status}
                                disabled={isEdit === 'true' ? false : true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>

                        </Grid>
                        {formik.values.questions.map((question: Question, index: number) => (
                            <Fragment key={index}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id={`question-${index}`}
                                        name={`question-${index}`}
                                        label={`Question ${index + 1}`}
                                        type="text"
                                        variant="outlined"
                                        onChange={formik.handleChange(`questions[${index}].question`)}
                                        value={formik.values.questions[index].question}
                                        disabled={isEdit === 'true' ? false : true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {question.options.map((option, optionIndex: number) => (
                                        <TextField
                                            key={optionIndex}
                                            required
                                            fullWidth
                                            id={`question-${index}-option-${optionIndex}`}
                                            name={`question-${index}-option-${optionIndex}`}
                                            label={formik?.values?.questions[index].options[optionIndex]===String(formik.values.questions[index].answer) ? "Right Answer":"Wrong Answer"}
                                            type="text"
                                            variant="outlined"
                                            onChange={formik.handleChange(`questions[${index}].options[${optionIndex}]`)}
                                            value={formik.values.questions[index].options[optionIndex]}
                                            sx={{ marginBottom: 2 }}
                                            disabled={isEdit === 'true' ? false : true}
                                        />
                                    ))}
                                    <TextField
                                        required
                                        fullWidth
                                        id={`question-${index}-answer`}
                                        name={`question-${index}-answer`}
                                        label="Answer"
                                        type="text"
                                        variant="outlined"
                                        onChange={formik.handleChange(`questions[${index}].answer`)}
                                        value={formik.values.questions[index].answer}
                                        disabled={isEdit === 'true' ? false : true}
                                    />
                                </Grid>
                            </Fragment>
                        ))}
                    </Grid>

                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                        {
                            isEditing ?
                                (
                                    <>
                                        <Button sx={{color: '#ED670A', mr:1, borderColor: '#ED670A', '&:hover': {borderColor: '#ED670A'}}} onClick={handleCancel} variant="outlined">
                                        {t('updatebreef.t1')}
                                        </Button>
                                        <Button sx={{backgroundColor: '#ED670A', '&:hover': {backgroundColor: '#d55a07'}}} type="submit" variant="contained">
                                        {t('updatebreef.t2')}
                                        </Button>
                                    </>
                                )
                                :
                                (
                                    <>
                                    </>
                                )
                        }
                    </Box>
                </Box>
            </Box>
        </Layout>
    )
}

export default UpdateViewBreef