import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  useMediaQuery,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux";
import { AppDispatch } from "../../redux/store";
import { deleteBreef } from "../../service/BreefService";
import { getTenant } from "../../service/TenantService";
import { getGame } from "../../service/GameService";
import { useAddBreefForm } from "../../utils/forms";
import { useTranslation } from "react-i18next";
import { DataObject } from "@mui/icons-material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: "bold",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const TableWrapper = styled(TableContainer)({
  margin: "auto",
  marginTop: "1%",
  width: "80%",
}) as typeof TableContainer;

interface Tenant {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  role: string;
  isEmailVerified: boolean;
  tenantId: string;
  teamId?: string;
  id: string;
}

interface Game {
  id: string;
  status: string;
  gameName: string;
}

interface Question {
  question: string;
  options: string[];
  answer: number;
  _id: string;
}

export interface Result {
  period: string;
  breefName: string;
  videoUrl: string;
  questions: Question[];
  tenantId: Tenant;
  gameId: Game;
  status: string;
  expiry: string;
  id: string;
}

interface BreefTableProps {
  results: Result[];
  page: number;
  limit: number;
  totalPages: number;
  totalResults: number;
}

const BreefTable: React.FC<BreefTableProps> = ({ results, page, limit, totalPages }) => {
  const [t] = useTranslation("global");
  const [id, setId] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");
  const formik = useAddBreefForm(id, isMobile);
  const dispatch: AppDispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleViewClick = async (breefId: string) => {
    setIsView(true);
    if (results) {
      const record = results.find((result) => result.id === breefId);
      if (record) {
        const mappedQuestions = record.questions.map((question) => ({
          question: question.question,
          options: question.options,
          answer: question.answer,
        }));
        // Fetch additional data
        const tenant = await getTenant(record.tenantId.id, isMobile, navigate);
        const game = await getGame(record.gameId.id, isMobile, navigate);
        formik.setValues({
          period: record.period,
          expiry: record.expiry,
          breefName: record.breefName,
          videoUrl: record.videoUrl,
          questions: mappedQuestions,
          gameId: record.gameId.id ?? "",
          tenantId: record.tenantId.id ?? "",
          gameName: game.gameName,
          tenantName: tenant.firstName + " " + tenant.lastName,
          status: record.status,
        });
      }
    }
    navigate(`/breef/${breefId}?isEdit=false`);
  };

  const handleEditClick = async (breefId: string) => {
    setIsEditing(true);
    setId(breefId);
    if (results) {
      const record = results.find((result) => result.id === breefId);
      if (record) {
        const mappedQuestions = record.questions.map((question) => ({
          question: question.question,
          options: question.options,
          answer: question.answer,
        }));
        // Fetch additional data
        const tenant = await getTenant(record.tenantId.id, isMobile, navigate);
        const game = await getGame(record.gameId.id, isMobile, navigate);
        formik.setValues({
          period: record.period,
          expiry: record.expiry,
          breefName: record.breefName,
          videoUrl: record.videoUrl,
          questions: mappedQuestions,
          gameId: record.gameId.id ?? "",
          tenantName: tenant.firstName + " " + tenant.lastName,
          tenantId: record.tenantId.id ?? "",
          gameName: game.gameName,
          status: record.status,
        });
      }
    }
    navigate(`/breef/${breefId}?isEdit=true`);
  };

  const handleDeleteClick = (breefId: string) => {
    setId(breefId);
    setIsDeleteModal(true);
  };

  const handleDeleteBreef = async () => {
    setIsDeleteModal(false);
    await deleteBreef(id, navigate, isMobile);
    setIsDelete(true);
  };

  const handleCancelDelete = () => {
    setIsDeleteModal(false);
  };

  return (
    <>
      <Dialog open={isDeleteModal} onClose={handleCancelDelete}>
        <DialogTitle>{t("breeftable.t1")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t("breeftable.t2")}</DialogContentText>
        </DialogContent>
        <Box sx={{ display: "flex", justifyContent: "center", m: 2 }}>
          <Button onClick={handleCancelDelete} color="primary" variant="outlined" sx={{ mr: 1 }}>
            {t("breeftable.t3")}
          </Button>
          <Button onClick={handleDeleteBreef} color="error" variant="contained">
            {t("breeftable.t4")}
          </Button>
        </Box>
      </Dialog>
      <TableWrapper component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ backgroundColor: "#ED670A" }} align="center">
                {t("breeftable.t5")}
              </StyledTableCell>
              <StyledTableCell sx={{ backgroundColor: "#ED670A" }} align="center">
                {t("breeftable.t6")}
              </StyledTableCell>
              <StyledTableCell sx={{ backgroundColor: "#ED670A" }} align="center">
                {t("breeftable.t7")}
              </StyledTableCell>
              <StyledTableCell sx={{ backgroundColor: "#ED670A" }} align="center">
                {t("breeftable.t8")}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results.map((breef) => {
              const satrtDate = new Date(breef?.period);
              const endDate = new Date(breef?.expiry);
              const satrtDateString = `${satrtDate.getDate().toString().padStart(2, "0")}/${(
                satrtDate.getMonth() + 1
              )
                .toString()
                .padStart(2, "0")}/${satrtDate.getFullYear()}`;
              const endDateString = `${endDate.getDate().toString().padStart(2, "0")}/${(
                endDate.getMonth() + 1
              )
                .toString()
                .padStart(2, "0")}/${endDate.getFullYear()}`;
              return (
                <StyledTableRow key={breef.id}>
                  <TableCell align="center">{breef.breefName}</TableCell>
                  <TableCell align="center">{breef.videoUrl}</TableCell>
                  <TableCell align="center"> {`${satrtDateString} - ${endDateString}`}</TableCell>
                  <TableCell align="center">
                    <IconButton aria-label="view" onClick={() => handleViewClick(breef.id)}>
                      <VisibilityIcon />
                    </IconButton>
                    <IconButton aria-label="edit" onClick={() => handleEditClick(breef.id)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton aria-label="delete" onClick={() => handleDeleteClick(breef.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableWrapper>
    </>
  );
};

export default BreefTable;
