import axios, { AxiosRequestConfig } from 'axios';
import { refreshAccessToken } from './refreshTokenService';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


export const updateNewInvitedUser = async (userId: string, userData: any) => {
    const accessToken = localStorage.getItem('access_token');
  
    console.log("User id: ", userId,accessToken);
    if (!userId || !accessToken) {
      throw new Error('User ID or access token missing');
    }
  
    try {
      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      
      const response = await axios.patch(`${API_BASE_URL}/v1/users/change-invite-user/${userId}`, userData, config);
      console.log("Updated new invited user details: ", response.data);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        // Access token expired, attempt to refresh token
        try {
          const newAccessToken = await refreshAccessToken();
          localStorage.setItem('accessToken', newAccessToken);  // Update the token in localStorage
  
          // Retry the request with the new access token
          const configWithNewToken: AxiosRequestConfig = {
            headers: {
              Authorization: `Bearer ${newAccessToken}`,
              'Content-Type': 'application/json',
            },
          };
          const retryResponse = await axios.patch(`${API_BASE_URL}/v1/users/change-invite-user/${userId}`, userData, configWithNewToken);
          console.log('Retry successful after token refresh:', retryResponse.data);
          return retryResponse.data;
        } catch (refreshError: any) {
          console.error('Failed to refresh token:', refreshError.message);
          throw new Error('Failed to refresh access token');
        }
      } else {
        console.error('Error updating new invited user details:', error.message);
        throw new Error('Failed to update new invited user details');
      }
    }
  };
  
  export const deleteUser = async (userId: string) => {
    const accessToken = localStorage.getItem('accessToken');
  
    if (!userId || !accessToken) {
      throw new Error('User ID or Team id or access token missing');
    }
  
    try {
      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
  
      const response = await axios.delete(`${API_BASE_URL}/v1/users/delteam/${userId}`, config);
      console.log("User deleted successfully:", response.data);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        // Access token expired, attempt to refresh token
        try {
          const newAccessToken = await refreshAccessToken();
          localStorage.setItem('accessToken', newAccessToken); // Update the token in localStorage
  
          // Retry the request with the new access token
          const configWithNewToken: AxiosRequestConfig = {
            headers: {
              Authorization: `Bearer ${newAccessToken}`,
            },
          };
          const retryResponse = await axios.delete(`${API_BASE_URL}/v1/users/delteam/${userId}`, configWithNewToken);
          console.log('Retry successful after token refresh:', retryResponse.data);
          return retryResponse.data;
        } catch (refreshError: any) {
          console.error('Failed to refresh token:', refreshError.message);
          throw new Error('Failed to refresh access token');
        }
      } else {
        console.error('Error deleting user:', error.message);
        throw new Error('Failed to delete user');
      }
    }
  };