import React, { ReactNode } from 'react';
import SideNavigation from './SideNavigation';

interface LayoutProps {
    children: ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
    return (
        <div className="layout" style={{display:'flex',justifyContent:'end'}}>
            <SideNavigation />
            <div className="content" style={{width:'80%'}}>{children}</div>
        </div>
    );
};

export default Layout;