import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearUserInfo, clearUserToken } from "../../redux/slices/AuthenticationSlice";
import { AppDispatch } from "../../redux/store";

const Logout=() =>{
    const navigate=useNavigate();
    const dispatch:AppDispatch=useDispatch();
    const clearData=()=>{
        dispatch(clearUserInfo(''));
        dispatch(clearUserToken(''));
        localStorage.removeItem('access_token')
        localStorage.removeItem('role')
        localStorage.removeItem('user')
    }
    useEffect(()=>{
        clearData();
        navigate('/');
    },[clearData])

  return (
    <div>Logout</div>
  )
}
export default Logout;
