import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  styled,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";
import { useAppDispatch, useAppSelector } from "../../redux";
import { AppDispatch } from "../../redux/store";
import { deleteGameById, getAllGames } from "../../service/GameService";
import { useAddGameForm } from "../../utils/forms";
import { useTranslation } from "react-i18next";
import { getAllCategories } from "../../service/CategoryService";

interface Category {
  categoryId: string;
  categoryName: string;
}
interface Game {
  gameName: string;
  categoryId: Category;
  gameCode: string;
  id: string;
  gameDescription: string;
}

interface ApiResponse {
  results: Game[];
  page: number;
  limit: number;
  totalPages: number;
  totalResults: number;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: "bold",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const TableWrapper = styled(TableContainer)({
  margin: "auto",
  marginTop: "1%",
  width: "80%",
}) as typeof TableContainer;

const Game = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const formik = useAddGameForm(isMobile);
  const [open, setOpen] = React.useState(false);
  const [titleText, setTitleText] = useState("Add New Game");
  const [isEditing, setIsEditing] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [deleteGameId, setDeleteGameId] = useState<string>();
  const dispatch: AppDispatch = useAppDispatch();
  const [categoriesData, setCategoriesData] = useState<[]>([]);
  const gamesData = useAppSelector((state) => state.games);
  const navigate = useNavigate();
  const [page, setPage] = useState(0); // Page number starts from 1
  const [rowsPerPage, setRowsPerPage] = useState(10); // Rows per page
  const handleClickOpen = () => {
    formik.resetForm();
    setTitleText("Add New Game");
    setOpen(true);
  };

  const fetchGames = async (page: number = 0, limit: number = rowsPerPage) => {
    await getAllGames(dispatch, isMobile, navigate, limit, page + 1); // Increment page by 1 since API pages start from 1
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    fetchGames(newPage, rowsPerPage); // Fetch games when page changes
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    fetchGames(0, parseInt(event.target.value, 10)); // Fetch games when rows-per-page changes
  };

  const fetchCategories = async () => {
    if (categoriesData && categoriesData.length === 0) {
      setCategoriesData(await getAllCategories(dispatch, isMobile, navigate));
    }
  };
  useEffect(() => {
    // Fetch categories
    fetchCategories();
  }, []);

  // const fetchGames = async (page?: number, limit?: number) => {
  //   let pageNo = 1;
  //   let pageLimit = 10;
  //   if (page) {
  //     pageNo = page;
  //   }
  //   if (limit) {
  //     pageLimit = limit;
  //   }
  //   await getAllGames(dispatch, isMobile, navigate, pageLimit, pageNo);
  // };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    formik.handleSubmit(e as any);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  const handleViewClick = (gameId: string) => {
    console.log(`View clicked for game with ID ${gameId}`);
    setIsView(true);
    navigate(`/game/${gameId}?isEdit=false`);
  };

  const handleEditClick = (gameId: string) => {
    console.log(`Edit clicked for user with ID ${gameId}`);
    setIsEditing(true);
    navigate(`/game/${gameId}?isEdit=true`);
  };

  const handleDeleteClick = (gameId: string) => {
    setDeleteGameId(gameId);
    setIsDelete(true);
  };

  const deleteGame = async () => {
    const deleteResponse = await deleteGameById(
      deleteGameId ? deleteGameId : "",
      isMobile,
      navigate
    );
    if (deleteResponse?.status === 204) {
      setIsDelete(false);
      fetchGames();
    }
  };

  const handleConfirmDelete = () => {
    setIsDelete(false);
  };

  const handleCreateGame = async () => {
    formik.handleSubmit();
    setOpen(false);
  };

  useEffect(() => {
    fetchGames();
  }, [formik.isSubmitting]);

  useEffect(() => {
    isView ? setTitleText("View Breef") : setTitleText("Edit Breef");
  }, [isEditing, isView]);
  const [t, i18n] = useTranslation("global");
  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "10%",
        }}
      >
        <h1>{t("game.t1")}</h1>

        <Button
          variant="contained"
          onClick={handleClickOpen}
          sx={{
            mt: 3,
            mb: 2,
            alignSelf: "flex-end",
            marginRight: "10%",
            backgroundColor: "#ED670A",
            "&:hover": { backgroundColor: "#d55a07" },
          }}
        >
          {t("game.t2")}
        </Button>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{titleText}</DialogTitle>
          <DialogContent>
            <DialogContentText>{t("game.t3")}</DialogContentText>
            <Box component="form" onSubmit={handleSubmit}>
              <TextField
                autoFocus
                required
                margin="dense"
                id="gameName"
                name="gameName"
                label="Game Name"
                type="text"
                fullWidth
                variant="outlined"
                onChange={formik.handleChange("gameName")}
                value={formik.values.gameName}
                error={formik.touched.gameName && Boolean(formik.errors.gameName)}
                helperText={formik.touched.gameName && formik.errors.gameName}
              />
              <TextField
                select
                required
                margin="dense"
                id="categoryId"
                name="categoryId"
                label="Category Name"
                type="text"
                fullWidth
                variant="outlined"
                onChange={formik.handleChange("categoryId")}
                value={formik.values.categoryId}
                error={formik.touched.categoryId && Boolean(formik.errors.categoryId)}
                helperText={formik.touched.categoryId && formik.errors.categoryId}
              >
                {categoriesData &&
                  categoriesData.map((category: any) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.categoryName}
                    </MenuItem>
                  ))}
              </TextField>
              {!isEditing && (
                <TextField
                  required
                  margin="dense"
                  id="gameCode"
                  name="gameCode"
                  label="Game Code"
                  type="text"
                  fullWidth
                  rows={4}
                  multiline
                  variant="outlined"
                  onChange={formik.handleChange("gameCode")}
                  value={formik.values.gameCode}
                  error={formik.touched.gameCode && Boolean(formik.errors.gameCode)}
                  helperText={formik.touched.gameCode && formik.errors.gameCode}
                  //disabled={isDisabledEditing}
                />
              )}
              <TextField
                required
                margin="dense"
                id="gameDescription"
                name="gameDescription"
                label="Game Description"
                type="text"
                fullWidth
                multiline
                variant="outlined"
                onChange={formik.handleChange("gameDescription")}
                value={formik.values.gameDescription}
                error={formik.touched.gameDescription && Boolean(formik.errors.gameDescription)}
                helperText={formik.touched.gameDescription && formik.errors.gameDescription}
              />
              <DialogActions>
                <Button onClick={handleClose}>{t("game.t4")}</Button>
                <Button onClick={handleCreateGame}>{t("game.t5")}</Button>
              </DialogActions>
            </Box>
          </DialogContent>
        </Dialog>

        <Dialog open={isDelete} onClose={handleConfirmDelete}>
          <DialogTitle>{t("game.t6")}</DialogTitle>
          <DialogContent>
            <DialogContentText>{t("game.t7")}</DialogContentText>
          </DialogContent>
          <Box sx={{ display: "flex", justifyContent: "center", m: 2 }}>
            <Button onClick={handleConfirmDelete} color="primary" variant="outlined" sx={{ mr: 1 }}>
              {t("game.t4")}
            </Button>
            <Button onClick={deleteGame} color="error" variant="contained">
              {t("game.t9")}
            </Button>
          </Box>
        </Dialog>

        <TableWrapper component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ backgroundColor: "#ED670A" }} align="center">
                  {t("game.t10")}
                </StyledTableCell>
                <StyledTableCell sx={{ backgroundColor: "#ED670A" }} align="center">
                  {t("game.t11")}
                </StyledTableCell>
                <StyledTableCell sx={{ backgroundColor: "#ED670A" }} align="center">
                  {t("game.t12")}
                </StyledTableCell>
                <StyledTableCell sx={{ backgroundColor: "#ED670A" }} align="center">
                  Description
                </StyledTableCell>
                <StyledTableCell sx={{ backgroundColor: "#ED670A" }} align="center">
                  {t("game.t13")}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {gamesData &&
                gamesData.games.results.map((game) => (
                  <StyledTableRow key={game.id}>
                    <TableCell align="center">{game.gameName}</TableCell>
                    <TableCell align="center">{game.gameCode}</TableCell>
                    <TableCell align="center">
                      {game.categoryId ? game.categoryId.categoryName : ""}
                    </TableCell>
                    <TableCell align="center" sx={{ maxWidth: "200px", wordWrap: "break-word" }}>
                      {game.gameDescription}
                    </TableCell>

                    <TableCell align="center">
                      <IconButton aria-label="view" onClick={() => handleViewClick(game.id)}>
                        <VisibilityIcon />
                      </IconButton>
                      <IconButton aria-label="edit" onClick={() => handleEditClick(game.id)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton aria-label="delete" onClick={() => handleDeleteClick(game.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
          {gamesData && (
            <TablePagination
              component="div"
              count={gamesData.games.totalResults || 0} // Total number of games
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            />
          )}
        </TableWrapper>
      </Box>
    </Layout>
  );
};

export default Game;
