import { MarkEmailRead, Sms, SmsFailed } from '@mui/icons-material';
import { Card, CardContent, Typography, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
interface CommunicationProps {
    smsSent: number;
    emailSent: number;
    smsLeftAccounts: number;
}

const StyledCard = styled(Card)(({ theme }) => ({
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.background.paper,
    borderRadius: theme.spacing(2),
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    transition: '0.3s',
    width: '23%',
    display: 'flex',
    alignItems: 'center',
    height: '200px',
    margin: '1%',
    '&:hover': {
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
    },
}));
const Title = styled(Typography)({
    marginBottom: '16px',
});

const Text = styled(Typography)({
    marginBottom: '8px',
    display: 'flex',
    alignItems: 'center',
});

const Icon = styled('span')({
    marginRight: '8px',
});

const Communication: React.FC<CommunicationProps> = ({ smsSent, emailSent, smsLeftAccounts }) => {
    const [t, i18n] = useTranslation("global");
    return (
        <StyledCard>
            <CardContent>
                <Title variant="h6" fontWeight="bold">{t('homecomms.t1')}</Title>
                <Text>
                    <Icon><Sms /></Icon>
                    {t('homecomms.t2')} {smsSent}
                </Text>
                <Text>
                    <Icon><MarkEmailRead /></Icon>
                    {t('homecomms.t3')} {emailSent}
                </Text>
                {/* <Text>
                    <Icon><SmsFailed /></Icon>
                    {t('homecomms.t4')} {smsLeftAccounts}
                </Text> */}
            </CardContent>
        </StyledCard>
    )
}
export default Communication
