import { Box, Button, Container, CssBaseline, Grid, TextField, ThemeProvider, Typography, createTheme, Link, useMediaQuery } from '@mui/material'
import React, { useState } from 'react'
import Logo from '../../asset/logo/LOGO_ORANGE.svg';
import { useForgetPasswordForm } from '../../utils/forms';
import { Copyright } from '../../utils/CopyRightPage';
const theme = createTheme({
    palette: {
        primary: {
            main: '#3f51b5',
        },
        secondary: {
            main: '#f50057'
        },
    },
});
export default function ForgetPassword() {
    const [formSubmitted, setFormSubmitted] = useState(false);
    const isMobile = useMediaQuery("(max-width: 600px)");
    const formik = useForgetPasswordForm(isMobile);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setFormSubmitted(true);
        formik.handleSubmit(e as any);
    };
    return (
        <ThemeProvider theme={theme}>
            <Container component='main' maxWidth='xs'>
                <CssBaseline />
                <Box
                    sx={{
                        mt: 5,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                    <img src={Logo} alt="Logo" style={{ width: '40%', height: '20%' }} />
                    <div style={{ background: theme.palette.primary.main, width: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center', borderRadius: '4px' }}>
                        <Typography component="h1" variant="h5" style={{
                            color: 'white', padding: '4px', marginTop: 3, marginBottom: 2
                        }}>
                            Forget Password
                        </Typography>
                    </div>
                    <Box component="form" onSubmit={handleSubmit}
                        sx={{ mt: 1 }}>
                        <TextField
                            margin='normal'
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name='email'
                            autoFocus
                            onChange={formik.handleChange("email")}
                            value={formik.values.email}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email} />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Forget Password
                        </Button>
                    </Box>
                    <Grid container>
                        <Grid item>
                            <Link href="/" variant="body2">
                                Already have an account? Sign in
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <Box mt={10}>
                <Copyright />
            </Box>
        </ThemeProvider>
    )
}
