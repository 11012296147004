import {
  Box,
  Button,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { updateGameHandler } from "../../service/GameService";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "../../redux";
import { getAllCategories } from "../../service/CategoryService";
import { AppDispatch } from "../../redux/store";
import { useTranslation } from "react-i18next";

const UpdateViewGame = () => {
  const [t, i18n] = useTranslation("global");
  const { gameId } = useParams();
  const query: any = new URLSearchParams(useLocation().search);
  const isEdit: string = query.get("isEdit");
  const navigate = useNavigate();
  const gamesData = useAppSelector((state) => state.games.games.results);
  const isMobile = useMediaQuery("(max-width:600px)");
  const [categoriesData, setCategoriesData] = useState<any[]>([]);
  const dispatch: AppDispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const open = Boolean(anchorEl);
  const [id, setId] = useState("");

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number, id: string) => {
    setId(id);
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [initialValues, setInitialValues] = useState({
    gameName: "",
    gameCode: "",
    categoryId: "",
    gameDescription: "",
  });

  const fetchGames = async () => {
    let categories: any[] = [];
    if (categoriesData && categoriesData.length === 0) {
      categories = await getAllCategories(dispatch, isMobile, navigate);
      setCategoriesData(categories);
    }
    // Find game data
    const data = gamesData.find((game) => game.id === gameId);
    if (data) {
      setInitialValues({
        gameName: data.gameName,
        gameCode: data.gameCode,
        categoryId: data.categoryId?.categoryName,
        gameDescription: data.gameDescription,
      });
      if (categories && categories.length > 0) {
        const categoryIndex = categories.findIndex(
          (category: any) => category.id === data.categoryId.id
        );
        setSelectedIndex(categoryIndex);
        setId(data.categoryId.id);
      }
    }
  };

  useEffect(() => {
    fetchGames();
  }, [isEdit, gameId]);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const dataToBePassed = {
        gameName: values.gameName,
        gameCode: values.gameCode,
        categoryId: id,
        gameDescription: values.gameDescription,
      };
      console.log(dataToBePassed);

      try {
        const response = await updateGameHandler(dataToBePassed, gameId);
        if (response?.status === 200 && response.statusText === "OK") {
          handleGoBack();
        }
      } catch (error) {
        console.log("Update game error: ", error);
      }
    },
  });

  const handleGoBack = () => {
    navigate("/game");
  };
  console.log("aaa: ", formik.values.categoryId);

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginX: "10%",
          marginBottom: "5%",
        }}
      >
        <h1>
          {isEdit === "true" ? "Update" : "View"} {t("updateview.t1")}
        </h1>

        <Box component="form" onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoFocus
                required
                fullWidth
                id="gameName"
                name="gameName"
                label="Game Name"
                type="text"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.gameName}
                disabled={isEdit !== "true"}
                error={formik.touched.gameName && Boolean(formik.errors.gameName)}
                helperText={formik.touched.gameName && formik.errors.gameName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="gameCode"
                name="gameCode"
                label="Game Code"
                type="text"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.gameCode}
                disabled={isEdit !== "true"}
                error={formik.touched.gameCode && Boolean(formik.errors.gameCode)}
                helperText={formik.touched.gameCode && formik.errors.gameCode}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="gameDescription"
                name="gameDescription"
                label="Game Description"
                type="text"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.gameDescription}
                disabled={isEdit !== "true"}
                error={formik.touched.gameDescription && Boolean(formik.errors.gameDescription)}
                helperText={formik.touched.gameDescription && formik.errors.gameDescription}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <List
                component="nav"
                aria-label="Device settings"
                sx={{ bgcolor: "background.paper" }}
              >
                {categoriesData && (
                  <ListItemButton
                    id="lock-button"
                    disabled={isEdit !== "true"}
                    aria-haspopup="listbox"
                    aria-controls="lock-menu"
                    aria-label="when device is locked"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClickListItem}
                    sx={{
                      border: "1px solid #ccc", // Adding border to the menu
                      borderRadius: "8px",
                      paddingY: "12px",
                      "& .MuiMenuItem-root": {
                        padding: "12px 24px", // Increase padding for larger menu items
                        fontSize: "1rem", // Increase font size if needed
                      },
                    }}
                  >
                    <ListItemText
                      primary={
                        categoriesData.length > 0 &&
                        selectedIndex !== -1 &&
                        categoriesData[selectedIndex]
                          ? categoriesData[selectedIndex].categoryName
                          : "Select Category"
                      }
                    />
                  </ListItemButton>
                )}
              </List>
              {categoriesData && (
                <Menu
                  id="lock-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "lock-button",
                    role: "listbox",
                  }}
                  PaperProps={{
                    sx: {
                      width: "260px", // Adjust width as needed
                      maxHeight: "400px", // Adjust height as needed
                    },
                  }}
                >
                  {categoriesData.map((category: any, index) => (
                    <MenuItem
                      key={category.id}
                      value={category.id}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index, category.id)}
                    >
                      {category.categoryName}
                    </MenuItem>
                  ))}
                </Menu>
              )}
            </Grid>
          </Grid>

          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={handleGoBack}
              variant="outlined"
              sx={{
                mr: 1,
                color: "#ED670A",
                borderColor: "#ED670A",
                "&:hover": { borderColor: "#d55a07" },
              }}
            >
              {t("updateview.t2")}
            </Button>

            {isEdit === "true" && (
              <Box>
                <Button
                  onClick={handleGoBack}
                  variant="outlined"
                  sx={{
                    mr: 1,
                    color: "#ED670A",
                    borderColor: "#ED670A",
                    "&:hover": { borderColor: "#d55a07" },
                  }}
                >
                  {t("updateview.t3")}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ backgroundColor: "#ED670A", "&:hover": { backgroundColor: "#d55a07" } }}
                >
                  {t("updateview.t4")}
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default UpdateViewGame;
