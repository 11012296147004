import { AccessTime, Group, Schedule } from "@mui/icons-material";
import { Card, CardContent, Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";

interface BreefInformationProps {
    totalBreef: number;
    avgBreefCreationTime: string;
    avgBreefCompletingTime: string
}

const StyledCard = styled(Card)(({ theme }) => ({
    backgroundColor: theme.palette.error.light,
    color: theme.palette.background.paper,
    borderRadius: theme.spacing(2),
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    transition: '0.3s',
    width: '40%',
    maxHeight: '250px',
    display: 'flex',
    alignItems: 'center',
    margin: '1%',
    '&:hover': {
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
    },
}));
const Title = styled(Typography)({
    marginBottom: '16px',
});

const Text = styled(Typography)({
    marginBottom: '8px',
    display: 'flex',
    alignItems: 'center',
});

const Icon = styled('span')({
    marginRight: '8px',
});

const BreefInformation: React.FC<BreefInformationProps> = ({ totalBreef, avgBreefCompletingTime, avgBreefCreationTime }) => {
    const [t, i18n] = useTranslation("global");
    return (
        <StyledCard>
            <CardContent>
                <Title variant="h6" fontWeight="bold">{t('homeinfo.t5')}</Title>
                <Text>
                    <Icon><Group /></Icon>
                    {t('homeinfo.t1')} {totalBreef}
                </Text>
                <Text>
                    <Icon><Schedule /></Icon>
                    {t('homeinfo.t3')} {avgBreefCreationTime}
                </Text>
                <Text>
                    <Icon><Schedule /></Icon>
                    {t('homeinfo.t4')} {avgBreefCompletingTime}
                </Text>
            </CardContent>
        </StyledCard>
    )
}
export default BreefInformation
