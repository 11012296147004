import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import Layout from '../../components/Layout';
import BreefInformation from './BreefInformation';
import Communication from './Communication';
import Overview from './Overview';
import TenantInfo from './TenantInfo';
import { getDashboardData } from '../../service/DashboardService';
import { useTranslation } from 'react-i18next';


export default function Home() {
    const [totalTenants, setTotalTenants] = useState(0);
    const [totalParticipants, setTotalParticipants] = useState(0);
    const [activeTenants, setActiveTenants] = useState(0);
    const [passiveTenants, setPassiveTenants] = useState(0);
    const [activeParticipants, setActiveParticipants] = useState(0);
    const [passiveParticipants, setPassiveParticipants] = useState(0);
    
    const [emailSent, setEmailSent] = useState(0);
    const [smsSent, setSmsSent] = useState(0);
    const [smsLeftAccounts, setSmsLeftAccounts] = useState(0);

    const [avgBreefCompletingTime, setAvgBreefCompletingTime] = useState('1:43:00');
    const [avgBreefCreationTime, setAvgBreefCreationTime] = useState('07:34:12');
    // const [avgTimeSpendToCreateBreef, setAvgTimeSpendToCreateBreef] = useState('00:43:21');
    const [totalBreef, setTotalBreef] = useState(0);


    const data = [
        { name: 'Active Tenants', value: 7 },
        { name: 'Passive Tenants', value: 3 },
    ];

    const data2 = [
        { name: 'Active Participants', value: 80 },
        { name: 'Passive Participants', value: 20 },
    ];
    useEffect(() => {
        const fetchData = async () => {
          try {
            const response: any = await getDashboardData();
            console.log("nfkwen", response);
            
            setTotalParticipants(response.totalUserCount);
            setActiveTenants(response.activeTenants);
            setActiveParticipants(response.activeUsers);
            setAvgBreefCreationTime(response.avgBreefCreationTime);
            setPassiveTenants(response.inActiveTenants);
            setPassiveParticipants(response.inactiveUsers);
            setTotalBreef(response.totalBreefCount);
            setTotalTenants(response.activeTenants + response.inactiveTenants);
            setEmailSent(response.totalEmail);
            setSmsSent(response.totalSms)

            console.log(activeTenants, passiveTenants, totalTenants);
            
          } catch (error) {
            console.error("Error fetching dashboard data", error);
          }
        };
    
        fetchData();
      }, []);
    const [t, i18n] = useTranslation("global");
    return (
        <Layout>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: '10%',
                }}>
                <h1 style={{ textAlign: 'center' }}>{t('home.t1')}</h1>

                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'baseline', flexWrap: 'wrap' }}>
                    <Overview totalTenants={totalTenants} totalParticipants={totalParticipants} />
                    <TenantInfo
                        activeTenants={activeTenants}
                        passiveTenants={passiveTenants}
                        activeParticipants={activeParticipants}
                        passiveParticipants={passiveParticipants}
                    />
                    <Communication emailSent={emailSent} smsLeftAccounts={smsLeftAccounts} smsSent={smsSent} />
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'baseline', flexWrap: 'wrap' }}>
                    <BreefInformation avgBreefCompletingTime={avgBreefCompletingTime} avgBreefCreationTime={avgBreefCreationTime} totalBreef={totalBreef} />
                </Box>
            </Box>
        </Layout>
    )
}
