import axios from "axios";
import { baseURL } from "../globals/constants";
import { NavigateFunction } from "react-router-dom";
import { showErrorToast } from "../utils/Toast";

export const getTenant = async (
    id: string,
    isMobile: boolean,
    navigate: NavigateFunction
) => {
    const token = localStorage.getItem("access_token");
    try {
        const response  = await axios.get(
            `${baseURL}/v1/users/${id}`,
            {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            },
        );
        return response.data;
    } catch (error: any) {
        if (error.response.status == 401) {
            showErrorToast("Session Expired.Please login", isMobile);
            localStorage.removeItem("access_token")
            navigate("/")
        }
        console.log("error ", error)
    }
};