import axios from "axios";
import { Dispatch } from "redux";
import { baseURL } from "../globals/constants";
import { setGamesData } from "../redux/slices/GameSlice";
import { showErrorToast } from "../utils/Toast";
import { NavigateFunction } from "react-router-dom";

interface Game{
    gameName: string;
    categoryId: string;
    gameCode: string;
}

export const getGame = async (
    id: string,
    isMobile: boolean,
    navigate: NavigateFunction
) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.get(
            `${baseURL}/v1/Games/${id}`,
            {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            },
        );
        return response.data;
    } catch (error: any) {
        if (error.response.status == 401) {
            showErrorToast("Session Expired.Please login", isMobile);
            localStorage.removeItem("access_token")
            navigate("/")
        }
        console.log("error ", error)
    }
};

export const getAllGames = async (
    dispatch:Dispatch, 
    isMobile: boolean,
    navigate: NavigateFunction,
    pageLimit?: number,
    pageNo?: number,
) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.get(
            `${baseURL}/v1/Games?limit=${pageLimit}&page=${pageNo}`,
            {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }
        );
        dispatch(setGamesData(response.data))
        console.log("final data: ", response.data)
        return response.data;
    } catch (error:any) {
        if (error.response.status == 401) {
            showErrorToast("Session Expired.Please login", isMobile);
            localStorage.removeItem("access_token")
            navigate("/")
        }
        console.log("error ", error)
    }
};

export const deleteGameById = async (gameId: string, isMobile:boolean, navigate: NavigateFunction) => {
    const token = localStorage.getItem("access_token");

    try {
        const response = await axios.delete(
            `${baseURL}/v1/Games/${gameId}`,
            {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }
        );
        console.log("Delete game by id ", response)
        return response;
    } catch(error:any) {
        if (error.response.status == 401) {
            showErrorToast("Session Expired.Please login", isMobile);
            localStorage.removeItem("access_token")
            navigate("/")
        }
        console.log("Delete Game error: ", error);
    }

}

export const updateGame = async (gameId: any) => {
    try {

    } catch(error) {
        console.log("updateGame error: ", error);
    }
}

export const getGameById = async (gameId: string, isMobile:boolean, navigate: NavigateFunction) => {
    const token = localStorage.getItem("access_token");

    try {
        const response = await axios.get(
            `${baseURL}/v1/Games/${gameId}`,
            {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }
        );
        console.log("Get game by id: ", response)
        return response?.data;
    } catch(error: any) {
        if (error.response.status == 401) {
            showErrorToast("Session Expired.Please login", isMobile);
            localStorage.removeItem("access_token")
            navigate("/")
        }
        console.log("getGameById error: ", error);
    }
}

export const createGame = async (inputBody: Game, isMobile:boolean, navigate:NavigateFunction) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.post(
            `${baseURL}/v1/Games`,
            inputBody,
            {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }
        );
        return response;
    } catch(error:any) {
        if (error.response.status == 401) {
            showErrorToast("Session Expired.Please login", isMobile);
            localStorage.removeItem("access_token")
            navigate("/")
        }else{
            const message = error?.response?.data?.message || "An error occurred while adding new game";
            showErrorToast(message, isMobile)
        }
    }
}

export const updateGameHandler = async (inputBody: any, gameId: any) => {
    const token = localStorage.getItem("access_token");

    try {
        const response = await axios.put(
            `${baseURL}/v1/Games/${gameId}`,
            inputBody,
            {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }
        )
        return response;
    } catch(error) {
        console.log("Update game error: ", error);
    }

}