import { configureStore } from "@reduxjs/toolkit";
import { userInformationSlice } from "./slices/AuthenticationSlice";
import { BreefsDataSlice } from "./slices/BreefsSlice";
import { TenantSlice } from "./slices/TenantSlice";
import { CategorySlice } from "./slices/CategorySlice";
import { GameSlice } from "./slices/GameSlice";

export const store = configureStore({
    reducer: {
        user: userInformationSlice.reducer,
        breefs: BreefsDataSlice.reducer,
        tenants: TenantSlice.reducer,
        category: CategorySlice.reducer,
        games: GameSlice.reducer
    }
})

export type AppStore = typeof store
export type RootState = ReturnType<AppStore['getState']>
export type AppDispatch = AppStore['dispatch']